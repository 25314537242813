import React, { Component } from 'react';
import './Mission-create.scss';

import MissionService from '../../../services/mission.service';
import PowerService from '../../../services/power.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class MissionNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      namePortuguese: '',
      contextPortuguese: '',
      toDoPortuguese: '',
      resultPortuguese: '',
      tipPortuguese: '',
      nameEnglish: '',
      contextEnglish: '',
      toDoEnglish: '',
      resultEnglish: '',
      tipEnglish: '',
      nameSpanish: '',
      contextSpanish: '',
      toDoSpanish: '',
      resultSpanish: '',
      tipSpanish: '',
      power: '',
      powers: []
    }
  }

  componentWillMount = async () => {
    const powers = await PowerService.getAll();
    await this.setState({ powers: powers })
  }

  handleInputChange = async (e) => {
    const target = e.target;

    await this.setState({
      [target.name]: target.value
    });
  }

  create = async () => {
    const ptProps = [
      this.state.namePortuguese,
      this.state.contextPortuguese,
      this.state.toDoPortuguese,
      this.state.resultPortuguese,
      this.state.tipPortuguese,
    ]

    const enProps = [
      this.state.nameEnglish,
      this.state.contextEnglish,
      this.state.toDoEnglish,
      this.state.resultEnglish,
      this.state.tipEnglish,
    ]

    const esProps = [
      this.state.nameSpanish,
      this.state.contextSpanish,
      this.state.toDoSpanish,
      this.state.resultSpanish,
      this.state.tipSpanish,
    ]

    const showError = (title) => {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>{ title }</p>
      })
    }

    const filledSpanish = esProps.filter(p => p === '').length;
    if (filledSpanish > 0 && filledSpanish < enProps.length) {
      showError('Campos em espanhol estão parcialmente preenchidos. Preencha todos ou nenhum.');
      return;
    }

    const filledEnglish = enProps.filter(p => p === '').length;
    if (filledEnglish > 0 && filledEnglish < enProps.length) {
      showError('Campos em inglês estão parcialmente preenchidos. Preencha todos ou nenhum.');
      return;
    }

    const filledPt = ptProps.filter(p => p === '').length;
    if (!this.state.power || filledPt > 0) {
      showError('Há campos obrigatórios que não foram preenchidos');
      return;
    }

    const mission = {
      power: this.state.power,
      pt: {
        name: this.state.namePortuguese,
        context: this.state.contextPortuguese,
        toDo: this.state.toDoPortuguese,
        result: this.state.resultPortuguese,
        tip: this.state.tipPortuguese
      },
      en: {
        name: this.state.nameEnglish,
        context: this.state.contextEnglish,
        toDo: this.state.toDoEnglish,
        result: this.state.resultEnglish,
        tip: this.state.tipEnglish
      },
      es: {
        name: this.state.nameSpanish,
        context: this.state.contextSpanish,
        toDo: this.state.toDoSpanish,
        result: this.state.resultSpanish,
        tip: this.state.tipSpanish
      }
    }

    try {
      let res = await MissionService.create(mission);
      if (res._id) {
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>OK</span>,
          icon: 'success',
          title: <p>Missão cadastrada com sucesso</p>
        })
        this.props.history.push('/missions')
      }
    }
    catch (err) {
      showError('Erro ao cadastrar missão.');
    }
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Nova missão</h1>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em português:<span className="highlighter">*</span></label>
                    <input type="text" name="namePortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.namePortuguese} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em inglês:</label>
                    <input type="text" name="nameEnglish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.nameEnglish} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Nome em espanhol:</label>
                    <input type="text" name="nameSpanish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.nameSpanish} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Contexto em português:<span className="highlighter">*</span></label>
                    <textarea name="contextPortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.contextPortuguese}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Contexto em inglês:</label>
                    <textarea name="contextEnglish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.contextEnglish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Contexto em espanhol:</label>
                    <textarea name="contextSpanish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.contextSpanish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>O que fazer em português:<span className="highlighter">*</span></label>
                    <textarea name="toDoPortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.toDoPortuguese}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>O que fazer em inglês:</label>
                    <textarea name="toDoEnglish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.toDoEnglish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>O que fazer em espanhol:</label>
                    <textarea name="toDoSpanish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.toDoSpanish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Resultado em português:<span className="highlighter">*</span></label>
                    <textarea name="resultPortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.resultPortuguese}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Resultado em inglês:</label>
                    <textarea name="resultEnglish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.resultEnglish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Resultado em espanhol:</label>
                    <textarea name="resultSpanish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.resultSpanish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Dica em português:<span className="highlighter">*</span></label>
                    <textarea name="tipPortuguese" className="form-control" required
                      onChange={this.handleInputChange} value={this.state.tipPortuguese}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Dica em inglês:</label>
                    <textarea name="tipEnglish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.tipEnglish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Dica em espanhol:</label>
                    <textarea name="tipSpanish" className="form-control"
                      onChange={this.handleInputChange} value={this.state.tipSpanish}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Poder:<span className="highlighter">*</span></label>
                    <select name="power" className="form-control" value={this.state.power}
                      onChange={this.handleInputChange}>
                      <option value="">Selecione o poder</option>
                      {
                        this.state.powers.map((item, index) => {
                          return (
                            <option value={item._id}>{item.pt.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row row-center">
                <div className="col-8">
                  <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="button-full btn-save"
                    type="button"
                    onClick={() => this.create()}
                  >
                    <i className="fa fa-check"></i> Cadastrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default MissionNew;