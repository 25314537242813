import requests from '../shared/agent';

const endpoint = '/workshops';

const WorkshopService = {
    getAll: async () => {
        return await requests.get(endpoint);
    },
    getById: async (id) => {
        return await requests.get(`${endpoint}/${id}`);
    },
    create: async (workshop) => {
        return await requests.post(endpoint, workshop);
    },
    edit: async (id, workshop) => {
        return await requests.put(`${endpoint}/${id}`, workshop);
    },
    changeActive: async(id, status) => {
        return await requests.post(`${endpoint}/${id}/active`, status)
    },
    remove: async(id) => {
        return await requests.del(`${endpoint}/${id}`);
    }
};

export default WorkshopService;