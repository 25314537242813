import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import config from "../config";

import LocalStorage from "../services/LocalStorage.service";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = config.apiUrl;
const responseBody = (res) => res.body;

const requests = {
  del: (url) => {
    return superagent
      .del(`${API_ROOT}${url}`)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },
  
  get: (url) => {
    return superagent
      .get(`${API_ROOT}${url}`)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },

  put: (url, body) => {
    return superagent
      .put(`${API_ROOT}${url}`, body)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },

  post: (url, body) => {
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .set({ authorization: LocalStorage.retrieve("token") })
      .then(responseBody);
  },
};

export default requests;
