import workshops from "../helpers/workshops";

function getValue(item) {
  let result = workshops.find(e => e.name === item);
  return result ? result.value : 0;
} 

export default function orderByWorkshop(item1, item2) {
  let v1 = getValue(item1);
  let v2 = getValue(item2);

  return v1 > v2 ? 1 : -1;
}