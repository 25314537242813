import React, { Component } from 'react';
import Toggle from "react-toggle";
import './Power.scss';

import PowerService from '../../services/power.service';
import SecurityService from '../../services/security.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthContext } from '../../context/authContext';
import { navigate, navigateUsingAchor } from '../../services/navigation.service';
const MySwal = withReactContent(Swal)

export default class Power extends Component {
  static contextType = AuthContext

  constructor() {
    super()
    this.state = {
      powers: []
    }
  }

  componentWillMount = async () => {
    const powers = await PowerService.getAll();
    await this.setState({
      powers: powers
    })
  }

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    PowerService.changeActive(id, { status }).catch(() => {})
  };

  removePower = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir um poder, ele não aparecerá mais para os usuários e não poderá ser mais ser utilizado no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Este poder ainda poderá ser restaurado</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await PowerService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Poderes</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Lista de Poderes</h3>
          <a 
            href="/powers/create" 
            className="button-full btn-create"
            onClick={(e) => navigateUsingAchor(e, this)}
          >
            <i className="fa fa-plus"></i> Criar poder
          </a>
        </div>

        <div className="row">
          {
            this.state.powers.map((item, index) => {
              return (
                <div className="box-card power-item" key={index}>
                  <img
                    alt={item.name}
                    className="power-image"
                    src={`https://ifa-app.s3.us-east-2.amazonaws.com/${item._id}/capa_pt.png`}
                  />
                  <h5 className="power-title">{item.pt.name}</h5>
                  <Toggle
                    id={item._id}
                    defaultChecked={item.active}
                    className="mt-2"
                    onChange={this.changeActive.bind(this)}
                  />

                  {/* {
                    SecurityService.isMaster() ?
                      <button
                        className="power-button remove"
                        onClick={() => this.removePower(item._id)}
                        title="Remover"
                      >
                        <i className="fa fa-times"></i>
                      </button> : <></>
                  } */}

                  <a
                    className="power-button edit"
                    href={`/powers/${item._id}`}
                    title="Editar"
                  >
                    <i className="fa fa-pen"></i>
                  </a>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}