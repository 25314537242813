import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const showAlert = ({
    title,
    callback,
    confirmButtonText = 'OK',
    allowOutsideClick = false
}) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        allowOutsideClick: allowOutsideClick,
        confirmButtonColor: '#87BF40',
        confirmButtonText: confirmButtonText,
        title: `<p>${title}</p>`,
      }).then(function () {
        callback? callback() : window.location = '/'
      });
}

export default showAlert;