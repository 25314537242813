import * as S3 from 'aws-sdk/clients/s3';

class AwsService {
    getBucket(){
        return new S3({
            accessKeyId: 'AKIA46P7ONVER2BNZN57',
            secretAccessKey: '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',
            region: 'us-east-2'
        });
    }

    uploadfile = async(file, name, id) => {
        console.log(file)
        const params = {
            Bucket: 'ifa-app',
            Key: `${id}/${name}`,
            Body: file,
            ACL: 'public-read',
            ContentType: file.type
        }

        try{
            console.log(`Realizando upload do arquivo ${file.name}... - Title: Upload de proposta`);
            await this.getBucket().upload(params).promise();
            console.log(`O arquivo ${file.name} foi enviado com sucesso.`);
            return true;
        } catch(err){
            console.log(err)
            console.log(`Erro ao enviar o arquivo ${file.name}.`);
            return false;
        }
    }

    getFiles = async(id) => {
        const params = {
            Bucket: 'ifa-app',
            Prefix: `${id}/`
        }
        
        try{
            let response = await this.getBucket().listObjects(params).promise();
            return response.Contents;
        } catch(er){
            console.log('Erro ao listar arquivos do servidor');
            return [];
        }
    }

    deleteFile = async(name) => {
        const params = {
            Bucket: 'ifa-app',
            Key: `${name}`
        };

        try{
            console.log(name);
            await this.getBucket().deleteObject(params).promise();
        } catch(er){
            console.log(er)
            console.log('Erro ao remover arquivo');
        }
    }

    download = (name) => {
        const params = {
            Bucket: 'ifa-app',
            Key: `${name}`
        }
        return this.getBucket().getSignedUrl('getObject',params);
    }
}

export default AwsService;