import moment from "moment";
import 'moment/locale/pt-br';

export default function (props) {

  return (
    <table className="table table-hover table-striped  mb-5">
      <thead>
        <tr className="text-center">
          <th></th>
          <th>{props.date}</th>
          <th>Total (desde {moment().startOf('year').utc().format('DD/MM/YYYY')})</th>
        </tr>
      </thead>
      <tbody>
        {
          props.items.map(mission => {

            return (

              <tr key={mission.name.pt}>
                <td>{mission.name.pt}</td>
                <td className="text-center">
                  {mission.amount.filteredByDate}
                  
                </td>
                <td className="text-center">
                  {mission.amount.sinceStartOfTheYear}
                </td>
              </tr>
            )

          })
        }
      </tbody>
    </table>
  );
}
