import React, { Component } from 'react';
import './Side-bar.scss';

import logo from '../../assets/images/logo/logo.png';
import logoHorizontal from '../../assets/images/logo/logo_name.png';

import LocalStorage from '../../services/LocalStorage.service';
import SecurityService from '../../services/security.service';
import { navigate } from '../../services/navigation.service';
import MenuItem from './components/MenuItem';
import { menuData } from './utils/menuData';
import { AuthContext } from '../../context/authContext';

class SideBar extends Component {
  static contextType = AuthContext
  
  constructor(props) {
    super(props)
    this.state = {
      menuToggled: false,
      toggleLogout: false
    }
  }

  toggleMenu = async () => {
    let menu = document.getElementById("sidemenu");
    let overlay = document.getElementById("overlay");
    if (!this.state.menuToggled) {
      this.state.menuToggled = true;
      menu.style.margin = "0";
      overlay.style.animation = "showup .35s";
      overlay.style.display = "block";
      document.body.style.overflow = "hidden";
      return;
    }
    this.state.menuToggled = false;
    menu.style.margin = "0 0 0 -256px";
    overlay.style.animation = "dismiss .35s";
    overlay.style.display = "none";
    document.body.style.overflow = "initial";
  }

  toggleLogout = async () => {
    let logout = document.getElementById("sidebar-logout");
    if (!this.state.toggleLogout) {
      logout.style.display = 'block';
      await this.setState({
        toggleLogout: true
      })
      return;
    }
    logout.style.display = 'none';
    await this.setState({
      toggleLogout: false
    })
  }

  navigate = (e, link) => {
    e.preventDefault();

    const navigator = navigate.bind(this);
    navigator({ link });
  }

  logout = () => {
    LocalStorage.clear();
    window.location.reload();
  }

  render() {
    return (
      <div>
        <div className="nav-margin hidden-md-up"></div>
        <div className="overlay" id="overlay" onClick={() => this.toggleMenu()}></div>

        {/* Mobile */}
        <nav className="navbar-ifa fixed-top hidden-md-up">

          <button className="navbar-toggler navbar-toggler-left" type="button"
            onClick={() => this.toggleMenu()}>
            <i className="fa fa-bars"></i>
          </button>
          <div className="navbar-brand">
            <img className="w" src={logo} alt="" />
            <img className="h" src={logoHorizontal} alt="" />
          </div>

          <div className="sidemenu" id="sidemenu">

            <div className="nav-btn borderless">
              <i className="fa fa-user"></i>
              <label>Admin IFA</label>
            </div>

            {
              menuData.map(item => 
                <MenuItem
                  key={item.link}
                  label={item.label}
                  icon={item.icon}
                  navigate={(e) => this.navigate(e, item.link)}
                  toggleMenu={this.toggleMenu}
                  isMobile
                />
              )
            }

            { SecurityService.isMaster() ?
              <a 
                href="/trash"
                onClick={(e) => this.navigate(e, '/trash')}
              >
                <div className="nav-btn borderless-b" onClick={() => this.toggleMenu()}>
                  <i className="fa fa-trash" />
                  <label>Lixeira</label>
                </div>
              </a> : <></>
            }

            <a 
              href="/login"
              onClick={(e) => this.navigate(e, '/login')}
            >
              <div className="nav-btn logout" onClick={() => this.logout()}>
                  <i className="fa fa-power-off"></i>
                  <label>Sair</label>
              </div>
            </a>

          </div>
        </nav>

        {/* Desktop */}
        <div id="sidebar">
          <div className="menu-logo">
            <img className="w" src={logo} alt="" />
            <img className="h" src={logoHorizontal} alt="" />
          </div>

          <div className="sidebar-item-wrappers">

            <a
              href="#sidebar-logout"
              onClick={() => this.toggleLogout()}
              data-toggle="collapse"
              aria-expanded="false"
              className="menu-item menu-item-has-items"
            >
              <div className="icon">
                <i className="fa fa-user"></i>
              </div>
              <label>
                Admin IFA
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </label>
            </a>

            <div id="sidebar-logout" className="collapse">
              {/* <div className="menu-item sub-item">
                <div className="icon">
                  <i className="fas fa-lock"></i>
                </div>
                <label>Alterar a senha</label>
              </div> */}
              <a 
                href="/login"
                onClick={(e) => this.navigate(e, '/login')}
              >
                <div className="menu-item sub-item" onClick={() => this.logout()}>
                  <div className="icon">
                    <i className="fa fa-power-off"></i>
                  </div>
                  <label>Sair</label>
                </div>
              </a>
            </div>

            {
              menuData.map(item => 
                <MenuItem
                  key={item.link}
                  label={item.label}
                  icon={item.icon}
                  navigate={(e) => this.navigate(e, item.link)}
                  toggleMenu={this.toggleMenu}
                />
              )
            }

            { SecurityService.isMaster() ?
              <a 
                href="/trash"
                onClick={(e) => this.navigate(e, '/trash')}
              >
                <div className="menu-item">
                  <div>
                    <i className="fa fa-trash"></i>
                  </div>
                  <label>Lixeira</label>
                </div>
              </a> : <></>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SideBar;