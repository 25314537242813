import requests from '../shared/agent';

const endpoint = '/participants'

const ParticipantService = {
    ranking: async() => {
        return await requests.get(`${endpoint}/ranking`)
    },
    report: async(query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/report${queryString}`)
    },
    getAll: async() => {
        return await requests.get(endpoint)
    },
    getById: async(id) => {
        return await requests.get(`${endpoint}/${id}`)
    },
    getByClass: async(code) => {
        return await requests.get(`${endpoint}/class/${code}`)
    },
    remove: async(id) => {
        return await requests.del(`${endpoint}/${id}`)
    },
    assignStars: async (participants) => {
        return await requests.post(`${endpoint}/assign-stars`, participants);
    },
    getParticipantsMissionInfo: async(classCode, workshopId) => {        
        return await requests.get(`${endpoint}/class/${classCode}/workshop/${workshopId}?type=mission`);
    },
    getParticipantsPowerInfo: async(classCode, workshopId) => {        
        return await requests.get(`${endpoint}/class/${classCode}/workshop/${workshopId}?type=power`);
    }
};

export default ParticipantService;