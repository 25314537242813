import Instructor from "../../Instructor/Instructor";
import InstructorNew from "../../Instructor/Instructor-create/Instructor-create";
import InstructorEdit from "../../Instructor/Instructor-edit/Instructor-edit";
import Workshop from "../../Workshop/Workshop";
import WorkshopNew from "../../Workshop/Workshop-create/Workshop-create";
import WorkshopEdit from "../../Workshop/Workshop-edit/Workshop-edit";
import Power from "../../Power/Power";
import License from "../../License/License";
import PowerNew from "../../Power/Power-create/Power-create";
import PowerEdit from "../../Power/Power-edit/Power-edit";
import Participant from "../../Participant/Participant";
import Mission from "../../Mission/Mission";
import MissionNew from "../../Mission/Mission-create/Mission-create";
import MissionEdit from "../../Mission/Mission-edit/Mission-edit";
import Quiz from "../../Quiz/Quiz";
import QuizNew from "../../Quiz/Quiz-create/Quiz-create";
import QuizEdit from "../../Quiz/Quiz-edit/Quiz-edit";
import Dashboard from "../../Dashboard/Dashboard";
import Training from "../../Training/Training";
import TrainingNew from "../../Training/Training-create/Training-create";
import TrainingEdit from "../../Training/Training-edit/Training-edit";
import Institution from "../../Institution/Institution";
import TrashCan from "../../TrashCan/TrashCan";
import Statistics from "../../Statistics/Statistics";

export const privateRoutesList = [
    {
      path: '/instructor/create',
      component: InstructorNew,
    },
    {
      path: '/instructor/:id',
      component: InstructorEdit,
    },
    {
      path: '/instructor',
      component: Instructor,
      exact: true
    },
    {
      path: '/workshop/create',
      component: WorkshopNew,
    },
    {
      path: '/workshop/:id',
      component: WorkshopEdit,
    },
    {
      path: '/workshop',
      component: Workshop,
    },
    {
      path: '/powers/create',
      component: PowerNew,
    },
    {
      path: '/powers/:id',
      component: PowerEdit,
    },
    {
      path: '/powers',
      component: Power,
    },
    {
      path: '/licenses',
      component: License,
    },
    {
      path: '/participant',
      component: Participant,
    },
    {
      path: '/missions/create',
      component: MissionNew,
    },
    {
      path: '/missions/:id',
      component: MissionEdit,
    },
    {
      path: '/missions',
      component: Mission,
    },
    {
      path: '/quizzes/create',
      component: QuizNew,
    },
    {
      path: '/quizzes/:id',
      component: QuizEdit,
    },
    {
      path: '/quizzes',
      component: Quiz,
    },
    {
      path: '/dashboard',
      component: Dashboard,
    },
    {
      path: '/trainings/create',
      component: TrainingNew,
    },
    {
      path: '/trainings/:id',
      component: TrainingEdit,
    },
    {
      path: '/trainings',
      component: Training,
    },
    {
      path: '/institutions',
      component: Institution,
    },
    {
      path: '/statistics',
      component: Statistics,
    },
    {
      path: '/trash',
      component: TrashCan,
      isMasterOnly: true,
    },
];