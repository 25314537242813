import moment from "moment";
import 'moment/locale/pt-br';

import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelService from "../../../services/excel.service";
import { missionsSheet } from "../../../utils/exportStatistics";


export default function (props) {

  function generalSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width }
      }
    }

    const columns = [
      getColumn("Informações gerais", 40),
      getColumn(props.dateFiltered, 20),
      getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
    ];

    const data = props.general.map(element => {
      if (element[1] === undefined)
        element[1] = "-"

      return element;
    });


    return ExcelService.formatData(columns, data);
  }

  function participantsByAge() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width }
      }
    }

    const columns = {
      participantsByAgeRange: [
        getColumn("", 20),
        getColumn(props.dateFiltered, 20),
        getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
      ],
      participantsByAge: [
        getColumn("", 20),
        getColumn(props.dateFiltered, 20),
        getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
      ]
    };

    const data = {
      participantsByAgeRange: props.participantsByAgeRange,
      participantsByAge: props.participantsByAge
    }


    let table1 = ExcelService.formatData(columns.participantsByAge, data.participantsByAge);
    let table2 = ExcelService.formatData(columns.participantsByAgeRange, data.participantsByAgeRange);
    let space = ExcelService.formatData([], []);

    let multiDataSet = Array.of(
      customHeader("Participantes geral", 20)[0],
      table1[0],
      space[0],
      customHeader("Somente de 10 a 18 anos", 20)[0],
      table2[0]
    );

    return multiDataSet;
  }

  function customHeader(title, width) {
    return [
      {
        columns: [{ title, width: { wch: width } }],
        data: []
      }
    ];
  }

  function participantsByGender() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width }
      }
    }

    const columns = [
      getColumn("Gênero", 40),
      getColumn(props.dateFiltered, 20),
      getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
    ];

    const data = props.participantsByGender;

    return ExcelService.formatData(columns, data);
  }

  function powersSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width }
      }
    }

    const columns = [
      getColumn("Poderes atribuídos", 40),
      getColumn(props.dateFiltered, 20),
      getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
    ];

    let totalYear = 0, totalFiltered = 0;
    const data = props.powers.map(element => {
      if (element[1] === "-") {
        totalFiltered = 0
        totalYear += element[2];
        return element
      }

      totalFiltered += element[1];
      totalYear += element[2];

      return element;
    });

    data.push(Array.of("Total", totalFiltered, totalYear));

    return ExcelService.formatData(columns, data);
  }

  function instructorsSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width }
      }
    }

    const columns = [
      getColumn("Instrutores", 40),
      getColumn(props.dateFiltered, 20),
      getColumn(moment().startOf('year').utc().format('DD/MM/YYYY'), 20),
    ];

    const data = props.instructors;

    return ExcelService.formatData(columns, data);
  }

  return (
    <ExcelFile
      element={
        <button className="button-full btn-export">
          <i className="fa fa-file-excel"></i> exportar
        </button>
      }
      filename={"Estatísticas"}
    >

      <ExcelSheet dataSet={generalSheet()} name="Geral"></ExcelSheet>
      <ExcelSheet dataSet={participantsByAge()} name="Participantes (idade)"></ExcelSheet>
      <ExcelSheet dataSet={participantsByGender()} name="Participantes (Gênero)"></ExcelSheet>
      <ExcelSheet dataSet={instructorsSheet()} name="Cadastro de instrutores"></ExcelSheet>
      <ExcelSheet dataSet={powersSheet()} name="Poderes"></ExcelSheet>
      <ExcelSheet dataSet={missionsSheet(props.missions, props.dateFiltered)} name="Missões"></ExcelSheet>

    </ExcelFile>
  );

}


