import React, { Component } from 'react';
import Toggle from "react-toggle";
import './Workshop.scss';

import WorkshopService from '../../services/workshop.service';
import SecurityService from '../../services/security.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AuthContext } from '../../context/authContext';
import { navigate, navigateUsingAchor } from '../../services/navigation.service';
const MySwal = withReactContent(Swal)

export default class Workshop extends Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)
    this.state = {
      workshops: []
    }
  }

  componentWillMount = async () => {
    const workshops = await WorkshopService.getAll();
    await this.setState({
      workshops: workshops
    })
  }

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    WorkshopService.changeActive(id, { status }).catch(() => {})
  };

  removeWorkshop = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir uma oficina, ela não aparecerá mais para os usuários e não poderá ser mais utilizada no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Esta oficina ainda poderá ser restaurada</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await WorkshopService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Oficinas</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Lista de Oficinas</h3>
          <a 
            href="/workshop/create" 
            className="button-full btn-create"
            onClick={(e) => navigateUsingAchor(e, this)}
          >
            <i className="fa fa-plus"></i> criar oficina
          </a>
        </div>

        <div className="row">
          {
            this.state.workshops.map((item, index) => {
              return (
                <div className="box-card workshop-item" key={index}>
                  <img
                    alt={item.pt.name}
                    className="workshop-image"
                    src={`https://ifa-app.s3.us-east-2.amazonaws.com/${item._id}/result_pt.png`}
                  />
                  <h5 className="workshop-title">{item.pt.name}</h5>
                  <Toggle
                    id={item._id}
                    defaultChecked={item.active}
                    className="mt-2"
                    onChange={this.changeActive.bind(this)}
                  />

                  {/* {SecurityService.isMaster() ?
                    <button
                      className="workshop-button remove"
                      onClick={() => this.removeWorkshop(item._id)}
                      title="Remover"
                    >
                      <i className="fa fa-times"></i>
                    </button> : <></>
                  } */}

                  <a
                    className="workshop-button edit"
                    href={`/workshop/${item._id}`}
                    title="Editar"
                  >
                    <i className="fa fa-pen"></i>
                  </a>
                </div>
              )
            })
          }
        </div>

      </div>
    )
  }
}