const sortInstructorsByDate = (res, type) => {
    const arraySorted = [...res.result];

    arraySorted.sort((a,b) => 
        type == 'expirationDate' ?
        new Date(a.institution.licenseExpireIn) - new Date(b.institution.licenseExpireIn) 
        : new Date(a.createdAt) - new Date(b.createdAt) 
    )

    res.result = arraySorted;

    return res
}

export default sortInstructorsByDate