import axios from "axios";
import LocalStorage from "../services/LocalStorage.service";
import config from "../config"

const axiosClient = axios.create({
  baseURL: config.apiUrl,
});

axiosClient.interceptors.request.use(async config => {
  const token = await LocalStorage.retrieve("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default axiosClient;