import { ExcelReport } from "../../../services/excel.service"
import moment from 'moment';

export const generalReport = (data) => {
    const excelReport = new ExcelReport();

    excelReport.setColumns([
        { title: 'Nome', width: 20 }, 
        { title: 'Email', width: 30 },
        { title: 'Data de Nascimento', width: 10 },
        { title: 'CPF', width: 10 }, 
        { title: 'Brasileiro', width: 20 },
        { title: 'Celular', width: 10 },
        { title: 'Rua', width: 20 },
        { title: 'Número', width: 10 },
        { title: 'Complemento', width: 10 },
        { title: 'Bairro', width: 20 },
        { title: 'CEP', width: 10 },
        { title: 'Cidade', width: 20 },
        { title: 'Estado', width: 10 },
        { title: 'País', width: 10 },
        { title: 'Professor', width: 10 }, 
        { title: 'Cursos', width: 40 }, 
        { title: 'Instituição', width: 40 },
        { title: 'Tipo de instituição', width: 20 },
        { title: 'Treinamento concluído', width: 10 },
        { title: 'Bloqueado', width: 10 },
        { title: 'Turma', width: 10 },
        { title: 'Nome da turma', width: 10 }, 
        { title: 'Oficina', width: 10 },
        { title: 'Local', width: 10 },
        { title: 'Oficina concluida', width: 10 },
        { title: 'Qtd Participantes', width: 10 }, 
        { title: 'Atendimentos', width: 10 },
        { title: 'Oficinas completas', width: 10 },
        { title: 'Atendimentos Totais', width: 10 },
        { title: 'Data cadastro instrutor', width: 20 },
        { title: 'Data realização oficina', width: 20 }, 
    ])

    data.forEach((item, index) => {
        item.classes.map(_class => 
            excelReport.addRow([
                { 
                    column: 'Nome',
                    value: item.name
                },
                { 
                    column: 'Email',
                    value: item.email
                }, 
                { 
                    column: 'Data de Nascimento',
                    value: item.born ? moment.utc(item.born).format("DD/MM/YYYY") : " - " 
                },
                { 
                    column: 'CPF',
                    value: item.cpf
                },
                { 
                    column: 'Brasileiro',
                    value: item.nationality == 'brasileiro' ? 'Sim' : "Não" 
                },
                { 
                    column: 'Celular',
                    value: item.cellphone 
                },
                { 
                    column: 'Rua',
                    value: item.address?.street 
                },
                { 
                    column: 'Número',
                    value: item.address?.number
                },
                {   
                    column: 'Complemento',
                    value: item.address?.complement 
                },
                {   
                    column: 'Bairro',
                    value: item.address?.neighborhood 
                },
                { 
                    column: 'CEP',
                    value: item.address?.zipcode 
                },
                { 
                    column: 'Cidade',
                    value: item.address?.city 
                },
                { 
                    column: 'Estado',
                    value: item.address?.state 
                },
                { 
                    column: 'País',
                    value: item.address?.country 
                },
                { 
                    column: 'Professor',
                    value: item.isTeacher ? "Sim" : "Não" 
                },
                { 
                    column: 'Cursos',
                    value: item.courses 
                },
                {
                    column: 'Instituição', 
                    value: item.institution.name 
                }, 
                { 
                    column: 'Tipo de instituição',
                    value: item.institution.type 
                },
                {   
                    column: 'Treinamento concluído',
                    value: item.trainingCompletedAt ? "Sim" : "Não" 
                },
                { 
                    column: 'Bloqueado',
                    value: !item.active ? "Sim" : "Não" 
                }, 
                {
                    column: 'Turma',
                    value: _class.code
                },
                { 
                    column: 'Nome da turma',
                    value: _class.name
                },
                { 
                    column: 'Oficina',
                    value: _class.workshop?.information?.pt?.name 
                },
                { 
                    column: 'Local',
                    value: _class.workshop?.localization 
                },
                { 
                    column: 'Oficina concluida',
                    value: _class.workshop?.completed ? "Sim" : " - " 
                }, 
                { 
                    column: 'Qtd Participantes',
                    value: _class.workshop?.presenceList?.length 
                },
                { 
                    column: 'Atendimentos',
                    value: _class.attendments ?? 0 
                },
                { 
                    column: 'Oficinas completas',
                    value: item.completedWorkshops ?? 0 
                },
                { 
                    column: 'Atendimentos Totais',
                    value: item.attendments ?? 0 
                },
                { 
                    column: 'Data cadastro instrutor',
                    value: item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - " 
                },
                { 
                    column: 'Data realização oficina',
                    value: _class.workshop?.dateTime ? moment(_class.workshop?.dateTime).format("DD/MM/YYYY") : " - " 
                },
              ])
        )
    })

    return excelReport.generate()
}

export const defaultReport = (data) => {
    const excelReport = new ExcelReport()

    excelReport.setColumns([
        { title: "Nome", width: 20, },
        { title: "Email", width: 30, },
        { title: "Data de Nascimento", width: 10, },
        { title: "CPF", width: 10, },
        { title: "Nacionalidade", width: 20, },
        { title: "Telefone", width: 10, },
        { title: "Celular", width:10},
        { title: "Rua", width: 60 },
        { title: "Numero", width: 10 },
        { title: "Complemento", width: 10 },
        { title: "Bairro", width: 20 },
        { title: "CEP", width: 10 },
        { title: "Cidade", width: 20 },
        { title: "Estado", width: 10 },
        { title: "País", width: 10 },
        { title: "Cursos", width: 40 },
        { title: "Instituição", width: 40 },
        { title: "Tipo de instituição", width: 40 },
        { title: "Site", width: 80 },
        { title: "Telefone da instituição", width: 10 },
        { title: "Rua da instituição", width: 60 },
        { title: "Numero da instituição", width: 10 },
        { title: "Complemento instituição", width: 10 },
        { title: "Bairro instituição", width: 20 },
        { title: "CEP instituição", width: 10 },
        { title: "Cidade instituição", width: 20 },
        { title: "Estado instituição", width: 10 },
        { title: "País instituição", width: 10 },
        { title: "Professor", width: 10 },
        { title: "Treinamento concluído", width: 10 },
        { title: "Status", width: 10 },
        { title: "Data cadastro instrutor", width: 20 },
        { title: "Número da Licença", width: 40 },
        { title: "Status da Licença", width:20 },
        { title: "Data de Expiração da Licença", width: 20 },
    ])

    data.forEach(item => {
        excelReport.addRow([
            { 
                column: 'Nome',
                value: item.name
            },
            { 
                column: 'Email',
                value: item.email
            },
            { 
                column: 'Data de Nascimento',
                value: item.born ? moment.utc(item.born).format("DD/MM/YYYY") : " - " 
            },
            {
                column: 'CPF', 
                value: item.cpf
            },
            { 
                column: 'Nacionalidade',
                value: item.nationality
            },
            { 
                column: 'Telefone',
                value: item.phone
            },
            { 
                column: 'Celular',
                value: item.cellphone
            },
            { 
                column: 'Rua',
                value: item.address.street
            },
            { 
                column: 'Numero',
                value: item.address.number
            },
            { 
                column: 'Complemento',
                value: item.address.complement
            },
            {
                column: 'Bairro',
                value: item.address.neighborhood
            },
            { 
                column: 'CEP',
                value: item.address.zipcode
            },
            { 
                column: 'Cidade',
                value: item.address.city
            },
            { 
                column: 'Estado',
                value: item.address.state
            },
            { 
                column: 'País',
                value: item.address.country
            },
            { 
                column: 'Cursos',
                value: item.courses
            },
            { 
                column: 'Instituição',
                value: item.institution.name
            },
            { 
                column: 'Tipo de instituição',
                value: item.institution.type
            },
            { 
                column: 'Site',
                value: item.institution.website
            },
            { 
                column: 'Telefone da instituição',
                value: item.institution.phone
            },
            {
                column: 'Rua da instituição',
                value: item.institution.address?.street
            },
            {
                column: 'Numero da instituição',
                value: item.institution.address?.number
            },
            {
                column: 'Complemento instituição',
                value: item.institution.address?.complement
            },
            {
                column: 'Bairro instituição',
                value: item.institution.address?.neighborhood
            },
            {
                column: 'CEP instituição',
                value: item.institution.address?.zipcode
            },
            {
                column: 'Cidade instituição',
                value: item.institution.address?.city
            },
            {
                column: 'Estado instituição',
                value: item.institution.address?.state
            },
            {
                column: 'País instituição',
                value: item.institution.address?.country
            },
            { 
                column: 'Professor',
                value: item.isTeacher ? "Sim" : "Não" 
            },
            { 
                column: 'Treinamento concluído',
                value: item.trainingCompletedAt ? "Sim" : "Não" 
            },
            { 
                column: 'Status',
                value: item.active ? "Ativo" : "Inativo" 
            },
            { 
                column: 'Data cadastro instrutor',
                value: item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - " 
            },
            {
                column: 'Número da Licença',
                value: item.institution.license
            },
            {
                column: 'Status da Licença',
                value: item.institution.licenseActive === true
                ? "Ativa" : "Inativa"
            },
            { 
                column: 'Data de Expiração da Licença',
                value: item.institution.licenseExpireIn ? moment(item.institution.licenseExpireIn).format("DD/MM/YYYY") : " - " 
            }
        ])
    })

    return excelReport.generate()

}