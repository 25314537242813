/**
 * @typedef {Object} Column
 * @property {string} title
 * @property {number} width
 * @property {Object} font
 * @property {boolean} font.bold
 * @property {string} font.color
 * @property {string} font.bgColor
 * 
 * @typedef {Object} Cell
 * @property {string} column - The column that this cell belongs to
 * @property {string | number} value - The value of this cell
 */

/**
 * ExcelReport class
 * @class
 */

class ExcelReport {
    #columns = []
    #rows = []

    #defaultStyles = {
        borderStyle: {
            style: "thin",
            color: "#000"
        },
        borderConfig: {
            top: this.borderStyle,
            bottom: this.borderStyle,
            left: this.borderStyle,
            right: this.borderStyle
        },
        header: {
            font: { 
                name: "Calibri", sz: 11, bold: true,
                color: { rgb: "ffffff" }
            },
            fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
            alignment: { vertical: "center", horizontal: "center" },
            border: this.borderConfig
        },
        cells: {
            font: { name: "Calibri", sz: 11 },
            alignment: { vertical: "center", horizontal: "center" },
            border: this.borderConfig
        },
        column: {
            title: "title",
            width: { wch: 20 },
            style: {
                font: { bold: true, color: { rgb: "ffffff" } },
                fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
                alignment: { vertical: "center", horizontal: "center" },
            }
        }
    }

    /**
     * Creates a new ExcelReport instance.
     * You can pass a new default style to the constructor to be used
     * as a base for all the styles.
     * @constructor
     * @param {Object} defaultStyle 
     */
    constructor(defaultStyle) {
        if(defaultStyle) this.#defaultStyles = defaultStyle
    }

    /**
     * Set the columns of the report.
     * You can pass a string and a Column object,
     * if you pass a string, the default column will be used.
     * All the columns name MUST BE UNIQUE.
     * @param {Column[] | string[]} columns 
     */
    setColumns(columns) {
        const columnsStyled = columns.map(column => 
            this.#getStyledColumn(
                typeof column === "string" ? { title: column } : column
            )
        )

        const hasDuplicatedColumns = columnsStyled.some((column, index) => 
            columnsStyled.findIndex(column2 => column2.title == column.title) !== index
        )

        if(hasDuplicatedColumns) throw new Error("Duplicated columns")

        this.#columns = columnsStyled
    }

    getColumns() {
        return this.#columns
    }

    /**
     * Add a new row to the report.
     * @param {Cell[]} cells 
     */
    addRow(cells) {
        const row = new Array(cells.length).fill({ 
            style: this.#defaultStyles.cells,
            value: " - " 
        });

        cells.forEach(cell => {
            const columnIndex = this.#columns.findIndex(column => column.title == cell.column)

            if(columnIndex === -1) throw new Error(`Column ${cell.column} not found`)

            row[columnIndex] = {
                ...row[columnIndex],
                value: cell.value ?? " - "
            }
        })

        this.#rows.push(row)
    }

    generate() {

        return [{
            columns: this.#columns,
            data: this.#rows
        }]
    }

    /** 
     * Returns a new column object with default styles and the specified title, width, and font options.
     * @param {Column} column
    */
    #getStyledColumn({ 
        title, 
        width = this.#defaultStyles.column.width.wch, 
        font = {}
    }) {

        const columnStyled = {
          ...this.#defaultStyles.column,
          title,
          width: { wch: width },
          style: {
            ...this.#defaultStyles.column.style,
            font: {
                ...this.#defaultStyles.column.style.font,
                bold: font.bold ?? this.#defaultStyles.column.style.font.bold,
                color: { 
                    rgb: font.color ?? this.#defaultStyles.column.style.font.color.rgb 
                }
            },
            fill: {
                ...this.#defaultStyles.column.style.fill,
                fgColor: { 
                    rgb: font.bgColor ?? this.#defaultStyles.column.style.fill.fgColor.rgb 
                }
            }
          }
        }
    
        return columnStyled
    }
}

const borderStyle = {
    style: "thin",
    color: "#000"
}

const border = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle
}

const styles = {
    header: {
        font: { 
            name: "Calibri", sz: 11, bold: true,
            color: { rgb: "ffffff" }
        },
        fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        alignment: { vertical: "center", horizontal: "center" },
        border
    },

    cells: {
        font: { name: "Calibri", sz: 11 },
        alignment: { vertical: "center", horizontal: "center" },
        border
    }
}

const ExcelService = {
    formatData(columns, rows, headerStyle = styles.header, cellsStyle = styles.cells) {

        return [{
            columns: columns.map(column => {
                return {
                    style: headerStyle,
                    ...column
                }
            }),
            data: rows.map(row => row.map(value => {
                return {
                    style: cellsStyle,
                    value: value ?? ""
                }
            }))
        }]
    },
};

export default ExcelService

export { ExcelReport }

