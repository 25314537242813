import requests from '../shared/agent';

const endpoint = '/instructors'

const InstructorService = {
    getAll: async () => {
        return await requests.get(endpoint)
    },
    getAllNames: async () => {
        return await requests.get(`${endpoint}/names`)
    },
    getById: async (id) => {
        return await requests.get(`${endpoint}/${id}`)
    },
    search: async(query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/search${queryString}`)
    },
    report: async (query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/report${queryString}`)
    },
    create: async (instructor) => {
        return await requests.post(`${endpoint}`, instructor)
    },
    changeActive: async(id, status) => {
        return await requests.post(`${endpoint}/${id}/active`, status)
    },
    changeLicense: async(id, status) => {
        return await requests.put(`${endpoint}/${id}/license/active`, status)
    },
    edit: async(id, instructor) => {
        return await requests.put(`${endpoint}/${id}`, instructor)
    },
    remove: async (id) => {
        return await requests.del(`${endpoint}/${id}`);
    }
};

export default InstructorService;

