import React, { useRef } from "react";

import "./EditWorkshops.scss";

import { BsListCheck } from "react-icons/bs";
import { BiRocket } from "react-icons/bi";
import { VscBeaker } from "react-icons/vsc";
import { AiOutlineStar } from "react-icons/ai";

import ReactTooltip from "react-tooltip";

export const EditWorkshops = ({ name, workshops, onClick }) => {
  const editPresenceList = useRef("editPresenceList");
  const attributePresenceList = useRef("attributePresenceList");
  const powers = useRef("powers");
  const missions = useRef("missions");
  const stars = useRef("stars");

  const currentClassType = JSON.parse(
    window.localStorage.getItem("currentClass")
  ).type;

  return (
    <div id="edit-workshop" className="container">
      <ul className="edit-workshop-content">
        <h3>{name}</h3>
        {workshops.map((workshop, index) => {
          return (
            <div className="edit-workshop-itens">
              <li key={index}>
                <label>{workshop.pt.name}</label>
                <div className="action-icons">
                  {workshop.presenceList.length > 0 ? (
                    <div>
                      <a
                        className="editPresenceList"
                        href="javascript:void(0)"
                        value="editPresenceList"
                        ref={editPresenceList}
                        onClick={() => onClick(workshop, editPresenceList)}
                      >
                        <BsListCheck data-tip data-for="editPresenceList" />
                        <ReactTooltip
                          id="editPresenceList"
                          place="top"
                          effect="solid"
                        >
                          <p className="tooltip-text">Editar lista de presença</p>
                        </ReactTooltip>
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a
                        className="attributePresenceList"
                        href="javascript:void(0)"
                        value="attributePresenceList"
                        ref={attributePresenceList}
                        onClick={() => onClick(workshop, attributePresenceList)}
                      >
                        <BsListCheck
                          data-tip
                          data-for="attributePresenceList"
                        />
                        <ReactTooltip
                          id="attributePresenceList"
                          place="top"
                          effect="solid"
                        >
                          <p className="tooltip-text">Atribuir presença</p>
                        </ReactTooltip>
                      </a>
                    </div>
                  )}

                  <div>
                    <a
                      className="powers"
                      href="javascript:void(0)"
                      value="powers"
                      ref={powers}
                      onClick={() => onClick(workshop, powers)}
                    >
                      <VscBeaker data-tip data-for="powers" />
                      <ReactTooltip id="powers" place="top" effect="solid">
                      <p className="tooltip-text">Editar poderes</p>
                      </ReactTooltip>
                    </a>
                  </div>
                  {currentClassType !== "campaignLevel1" ? (
                    <>
                      <div>
                        <a
                          className="missions"
                          href="javascript:void(0)"
                          value="missions"
                          ref={missions}
                          onClick={() => onClick(workshop, missions)}
                        >
                          <BiRocket data-tip data-for="missions" />
                          <ReactTooltip
                            id="missions"
                            place="top"
                            effect="solid"
                          >
                            <p className="tooltip-text">Editar missões</p>
                          </ReactTooltip>
                        </a>
                      </div>
                      <div>
                        <a
                          className="stars"
                          href="javascript:void(0)"
                          value="stars"
                          ref={stars}
                          onClick={() => onClick(workshop, stars)}
                        >
                          <AiOutlineStar data-tip data-for="stars" />
                          <ReactTooltip id="stars" place="top" effect="solid">
                          <p className="tooltip-text">Editar estrelas</p>
                          </ReactTooltip>
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
