import LocalStorage from './LocalStorage.service';

const masterUsername = "master";

const SecurityService = {

    isLoggedIn() {
        return (LocalStorage.retrieve('token') !== null && LocalStorage.retrieve('token') !== 'undefined');
    },
    getToken() {
        return LocalStorage.retrieve('token');
    },
    getUser(){
        return LocalStorage.retrieve('user');
    },
    isMaster() {
        return !this.isLoggedIn() ? false : this.getUser().username === masterUsername;
    },
    setToken(token) {
        LocalStorage.persist('token', token);
    },
    clear() {
        LocalStorage.clear();
    }
};

export default SecurityService;

