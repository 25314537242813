
const MenuItem = ({ 
    label, 
    icon, 
    navigate, 
    isMobile,
    toggleMenu
}) => {
    const onClick = (e) => {
        toggleMenu()
        navigate(e)
    }

    if(isMobile) {
        return (
            <a href="#" onClick={onClick}>
                <div className="nav-btn borderless-b">
                    <i className={`fas ${icon}`}></i>
                    <label>{label}</label>
                </div>
            </a>
        )
    }
    return (
        <a href="#" onClick={navigate}>
            <div className="menu-item item-border">
                <div className="icon">
                    <i className={`fas ${icon}`}></i>
                </div>
                <label>{label}</label>
            </div>
        </a>
    )
}

export default MenuItem