import './Instructor.scss';

import moment from 'moment';
import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { AuthContext } from '../../context/authContext';
import InstructorService from '../../services/instructor.service';
import { navigate, navigateUsingAchor } from '../../services/navigation.service';
import Loading from '../Loading/Loading';
import PaginationWithSearch from '../PaginationWithSearch/PaginationWithSearch';
import InstructorItem from './components/InstructorItem/InstructorItem';
import { defaultReport, generalReport } from './utils/generateExcel';

const MySwal = withReactContent(Swal);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



class Instructor extends Component {
  static contextType = AuthContext
  
  constructor() {
    super();
    this.state = {
      instructors: [],
      page: 0,
      dataSliceBasedOnPage: [0,1],
      report: [],
      initial: '',
      final: '',
      name: '',
      instituition: '',
      loading: true
    };

  }


  showAlert(buttonText, icon, title) {
    MySwal.fire({
      confirmButtonColor: '#87BF40',
      confirmButtonText: <span>{buttonText}</span>,
      icon: icon,
      title: <p>{title}</p>
    })
  }

  componentDidMount = async () => {
    const instructors = await InstructorService.getAll();

    this.setState({
      instructors,
      loading: false
    });
  };

  changeActive = async e => {
    const id = e.target.id;
    const status = e.target.checked;
    let res = await InstructorService.changeActive(id, { status });
  };


  renderButtonExportExcel() {
    if (this.state.instructors) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-export">
              <i className="fa fa-file-excel"></i> exportar
            </button>
          }
          filename={"cadastro instrutor"}
        >
          <ExcelSheet dataSet={defaultReport(this.state.instructors)} name="Instrutores"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  buttonGenerateReportBlockAndUnblock(block) {
    if (block) {
      this.btnGenerateReport.innerHTML = "Carregando..."
      this.btnGenerateReport.setAttribute("disabled", "true");
    } else {
      this.btnGenerateReport.innerHTML = "gerar relatorio"
      this.btnGenerateReport.removeAttribute("disabled");
    }
  }

  validateDateToReport = async () => {

    await this.setState({
      report: []
    });

    if ((this.state.initial != '' && this.state.final == '') || (this.state.initial == '' && this.state.final != '')) {
      this.buttonGenerateReportBlockAndUnblock(false);
      return this.showAlert('OK', 'error', 'Para filtragem de datas, a data inicial e a data final precisam ser preenchidos');
    }

    this.buttonGenerateReportBlockAndUnblock(true);
    if (moment(this.state.final).diff(moment(this.state.initial)) < 0) {
      this.buttonGenerateReportBlockAndUnblock(false);
      return this.showAlert('OK', 'error', 'A data final não pode ser inferior a inicial');
    }

    const { metadata, result } = await InstructorService.report(
      { start: this.state.initial, end: this.state.final, name: this.state.name, institution: this.state.instituition });


    await this.setState({
      report: result
    });

    if (!metadata.count) {
      this.buttonGenerateReportBlockAndUnblock(false);
      return this.showAlert('OK', 'warning', 'Não existem dados para serem exibidos!');
    }

    this.buttonGenerateReportBlockAndUnblock(false);
  }

  renderReportExcelButton() {
    if (this.state.report.length) {
      return (
        <ExcelFile
          element={
            <button className="button-full btn-create modal-btn button-w-100">
              <i className="fa fa-file-excel"></i> download
            </button>
          }
          filename={"relatório instrutor"}
        >
          <ExcelSheet dataSet={generalReport(this.state.report)} name="relatorio-instrutores-completo"></ExcelSheet>
        </ExcelFile>
      );
    }
  }

  removeInstructor = (id) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Mover para a lixeira?</span>,
      html: <p style={{ color: '#000' }}>
        Ao excluir um instrutor, ele não aparecerá mais para os usuários e não poderá ser mais ser utilizado no aplicativo.<br /><br />
        <span style={{ color: '#FFC107' }}>Este instrutor ainda poderá ser restaurado</span>
      </p>
    }).then(async (result) => {
      if (result.value) {
        let res = await InstructorService.remove(id);
        if (res._id) {
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
          
      }
    }).catch(err => {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: <span>VOLTAR</span>,
        icon: "error",
        title: <span style={{ color: '#FFC107' }}>Não foi possível excluir</span>,
        html: <p style={{ color: '#000' }}>{err.response.body.message}</p>
      });
    })
  }

  render() {

    return (
      <>
        <div className="content-card">
          <div className="row page-title">
            <h1>Instrutores</h1>
          </div>

          <div className="row content-header">
            <h3 className="list-title">Lista de Instrutores</h3>

            <div className="row">
              <a 
                href="/instructor/create" 
                className="button-full btn-create m-right"
                onClick={(e) => navigateUsingAchor(e, this)}
              >
                <i className="fa fa-plus"></i> criar instrutor
              </a>
              {this.renderButtonExportExcel()}

              <a className="button-full btn-export" data-toggle="modal" data-target="#exampleModal">
                <i className="fa fa-file-excel"></i> relatório geral
              </a>

            </div>
          </div>

          <Loading status={this.state.loading}>
            <div className="row">
              <div className="col-md-12">
                <PaginationWithSearch
                  data={this.state.instructors}
                  itemsPerPage={20}
                >
                  <PaginationWithSearch.Searchbox 
                    placeholder="Pesquisar por nome, email ou CPF..."
                  />
                  <PaginationWithSearch.Buttons />
                  <table className="table table-responsive table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>CPF</th>
                        <th>Instituição</th>
                        <th>Professor</th>
                        <th>Ativo</th>
                        <th className="actions">Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      <PaginationWithSearch.Data
                        render={(item) => 
                          <InstructorItem
                            key={item?._id}
                            item={item}
                            changeActive={this.changeActive.bind(this)}
                            navigate={(e) => navigateUsingAchor(e, this)}
                            removeInstructor={() => this.removeInstructor(item._id)}
                          />
                        }
                      />
                    </tbody>
                  </table>
                </PaginationWithSearch>
              </div>
            </div>
          </Loading>

        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="list-title">Relatório completo de Instrutores</h3>
                <button className="power-button remove closed" data-dismiss="modal" aria-label="Close" title="Remover"><i className="fa fa-times"></i></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nome do Instrutor:</label>
                      <input type="text" name="name" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} required defaultValue={this.state.name} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nome da Instituição:</label>
                      <input type="text" name="Instituition" className="form-control" onChange={(e) => { this.setState({ instituition: e.target.value }) }} required defaultValue={this.state.instituition} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Data Inicial da Oficina:</label>
                      <input type="date" name="initialDate" className="form-control" onChange={(e) => { this.setState({ initial: e.target.value }) }} required defaultValue={this.state.initial} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Data Final da Oficina:</label>
                      <input type="date" name="finalDate" className="form-control" onChange={(e) => { this.setState({ final: e.target.value }) }} required defaultValue={this.state.final} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div class="col-md-12">
                  <button className="button-full btn-create modal-btn button-w-100" ref={el => this.btnGenerateReport = el}
                    onClick={this.validateDateToReport.bind(this)}> gerar relatorio
                  </button>
                </div>
                <div class="col-md-12">
                  {this.renderReportExcelButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Instructor;