import React, { Component } from 'react';
import './Workshop-edit.scss';
import * as moment from 'moment';

// import ParticipantService from '../../services/instructor.service';
import WorkshopService from '../../../services/workshop.service';
import AwsService from '../../../services/aws.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class WorkshopEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            namePortuguese: '',
            descriptionPortuguese: '',
            nameEnglish: '',
            descriptionEnglish: '',
            nameSpanish: '',
            descriptionSpanish: '',
            fileApresentationPortuguese: '',
            fileHandsonPortuguese: '',
            fileResultPortuguese: '',
            fileApresentationEnglish: '',
            fileHandsonEnglish: '',
            fileResultEnglish: '',
            fileApresentationSpanish: '',
            fileHandsonSpanish: '',
            fileResultSpanish: '',
            fileApresentationAuxPortuguese: '',
            fileHandsonAuxPortuguese: '',
            fileResultAuxPortuguese: '',
            fileApresentationAuxEnglish: '',
            fileHandsonAuxEnglish: '',
            fileResultAuxEnglish: '',
            fileApresentationAuxSpanish: '',
            fileHandsonAuxSpanish: '',
            fileResultAuxSpanish: '',
            AwsService: new AwsService(),
            downloadUrl: ''
        }
    }

    componentWillMount = async () => {
        let { id } = this.props.match.params;
        let workshop = await WorkshopService.getById(id);

        await this.setState({
            id: workshop._id,
            namePortuguese: workshop.pt.name,
            descriptionPortuguese: workshop.pt.description,
            nameEnglish: workshop.en.name,
            descriptionEnglish: workshop.en.description,
            nameSpanish: workshop.es.name,
            descriptionSpanish: workshop.es.description
        })

        await this.getS3Files();
    }

    getS3Files = async () => {
        await this.setState({
            fileApresentationPortuguese: '',
            fileHandsonPortuguese: '',
            fileResultPortuguese: '',
            fileApresentationEnglish: '',
            fileHandsonEnglish: '',
            fileResultEnglish: '',
            fileApresentationSpanish: '',
            fileHandsonSpanish: '',
            fileResultSpanish: ''
        });

        let files = await this.state.AwsService.getFiles(this.state.id);
        files = files.filter(file => file.Key.split('/')[1] != '');

        files.forEach(async file => {
            if (file.Key.includes('apresentation_pt'))
                await this.setState({ fileApresentationPortuguese: [file] })
            else if (file.Key.includes('handson_pt'))
                await this.setState({ fileHandsonPortuguese: [file] })
            else if (file.Key.includes('result_pt'))
                await this.setState({ fileResultPortuguese: [file] })
            else if (file.Key.includes('apresentation_en'))
                await this.setState({ fileApresentationEnglish: [file] })
            else if (file.Key.includes('handson_en'))
                await this.setState({ fileHandsonEnglish: [file] })
            else if (file.Key.includes('result_en'))
                await this.setState({ fileResultEnglish: [file] })
            else if (file.Key.includes('apresentation_es'))
                await this.setState({ fileApresentationSpanish: [file] })
            else if (file.Key.includes('handson_es'))
                await this.setState({ fileHandsonSpanish: [file] })
            else if (file.Key.includes('result_es'))
                await this.setState({ fileResultSpanish: [file] })
        })
    }

    download = async (name) => {
        await this.setState({
            downloadUrl: this.state.AwsService.download(name)
        })
    }

    deleteFile = async(name) => {
        MySwal.fire({
            allowOutsideClick: false,
            buttonsStyling: false,
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'CONCLUIR',
            customClass: {
              confirmButton: 'confirm-button-class',
              cancelButton: 'cancel-button-class'
            },
            icon: 'warning',
            reverseButtons: true,
            showCloseButton: true,
            showCancelButton: true,
            title: <span style={{ color: '#E81E47' }}>Excluir imagem?</span>,
            html: <p style={{ color: '#000' }}>
              Ao excluir uma imagem, ela não aparecerá mais para os usuários, será necessário carregar outra imagem.<br /><br />
              <span style={{ color: '#E81E47' }}>Essa ação é irreversível!</span>
            </p>,
          }).then(async (result) => {
            if (result.value){
                console.log(name)
                await this.state.AwsService.deleteFile(name);
                await this.getS3Files();
            }
        })
    }

    handleInputChange = async (e) => {
        const target = e.target;

        await this.setState({
            [target.name]: target.value
        });
    }

    edit = async () => {
        console.log(this.state)
        if (this.state.namePortuguese == '' || this.state.nameEnglish == '' || this.state.nameSpanish == '' 
                                            || this.state.descriptionPortuguese == ''
                                            || this.state.descriptionEnglish == ''
                                            || this.state.descriptionSpanish == ''
                                            || this.state.fileApresentationPortuguese == ''
                                            || this.state.fileHandsonPortuguese == ''
                                            || this.state.fileResultPortuguese == ''
                                            || this.state.fileApresentationEnglish == ''
                                            || this.state.fileHandsonEnglish == ''
                                            || this.state.fileResultEnglish == ''
                                            || this.state.fileApresentationSpanish == ''
                                            || this.state.fileHandsonSpanish == ''
                                            || this.state.fileResultSpanish == '') {
            MySwal.fire({
                confirmButtonColor: '#87BF40',
                confirmButtonText: <span>OK</span>,
                icon: 'error',
                title: <p>Há campos obrigatórios que não foram preenchidos. Lembre-se: ao selecionar uma imagem, deve-se clicar no botão "Enviar".</p>
            })
        } else {

            const workshop = {
                pt: {
                    name: this.state.namePortuguese,
                    description: this.state.descriptionPortuguese
                },
                en: {
                    name: this.state.nameEnglish,
                    description: this.state.descriptionEnglish
                },
                es: {
                    name: this.state.nameSpanish,
                    description: this.state.descriptionSpanish
                }
            }

            try {
                let res = await WorkshopService.edit(this.state.id, workshop);
                if (res._id) {
                    MySwal.fire({
                        confirmButtonColor: '#87BF40',
                        confirmButtonText: <span>OK</span>,
                        icon: 'success',
                        title: <p>Oficina atualizada com sucesso</p>
                    })
                    this.props.history.push('/workshop')
                }
            } catch (err) {
                console.log(err)
                MySwal.fire({
                    confirmButtonColor: '#87BF40',
                    confirmButtonText: <span>Tentar novamente</span>,
                    icon: 'error',
                    title: <p>Erro ao atualizar oficina.</p>
                })
            }
        }
    }

    selectFile = async (e) => {
        const input = e.target;

        if (input.id == 'fileApresentationAuxPortuguese')
            await this.setState({ fileApresentationAuxPortuguese: input.files })
        else if (input.id == 'fileHandsonAuxPortuguese')
            await this.setState({ fileHandsonAuxPortuguese: input.files })
        else if (input.id == 'fileResultAuxPortuguese')
            await this.setState({ fileResultAuxPortuguese: input.files })
        else if (input.id == 'fileApresentationAuxEnglish')
            await this.setState({ fileApresentationAuxEnglish: input.files })
        else if (input.id == 'fileHandsonAuxEnglish')
            await this.setState({ fileHandsonAuxEnglish: input.files })
        else if (input.id == 'fileResultAuxEnglish')
            await this.setState({ fileResultAuxEnglish: input.files })
        else if (input.id == 'fileApresentationAuxSpanish')
            await this.setState({ fileApresentationAuxSpanish: input.files })
        else if (input.id == 'fileHandsonAuxSpanish')
            await this.setState({ fileHandsonAuxSpanish: input.files })
        else if (input.id == 'fileResultAuxSpanish')
            await this.setState({ fileResultAuxSpanish: input.files })

        console.log(this.state)
    }

    upload = async (name) => {
        try {
            if (name == 'apresentationPortuguese' && this.state.fileApresentationAuxPortuguese.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileApresentationAuxPortuguese[0],
                    `apresentation_pt.${this.state.fileApresentationAuxPortuguese[0].name.split('.')[this.state.fileApresentationAuxPortuguese[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'handsonPortuguese' && this.state.fileHandsonAuxPortuguese.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileHandsonAuxPortuguese[0],
                    `handson_pt.${this.state.fileHandsonAuxPortuguese[0].name.split('.')[this.state.fileHandsonAuxPortuguese[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'resultPortuguese' && this.state.fileResultAuxPortuguese.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileResultAuxPortuguese[0],
                    `result_pt.${this.state.fileResultAuxPortuguese[0].name.split('.')[this.state.fileResultAuxPortuguese[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'apresentationEnglish' && this.state.fileApresentationAuxEnglish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileApresentationAuxEnglish[0],
                    `apresentation_en.${this.state.fileApresentationAuxEnglish[0].name.split('.')[this.state.fileApresentationAuxEnglish[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'handsonEnglish' && this.state.fileHandsonAuxEnglish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileHandsonAuxEnglish[0],
                    `handson_en.${this.state.fileHandsonAuxEnglish[0].name.split('.')[this.state.fileHandsonAuxEnglish[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'resultEnglish' && this.state.fileResultAuxEnglish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileResultAuxEnglish[0],
                    `result_en.${this.state.fileResultAuxEnglish[0].name.split('.')[this.state.fileResultAuxEnglish[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'apresentationSpanish' && this.state.fileApresentationAuxSpanish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileApresentationAuxSpanish[0],
                    `apresentation_es.${this.state.fileApresentationAuxSpanish[0].name.split('.')[this.state.fileApresentationAuxSpanish[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'handsonSpanish' && this.state.fileHandsonAuxSpanish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileHandsonAuxSpanish[0],
                    `handson_es.${this.state.fileHandsonAuxSpanish[0].name.split('.')[this.state.fileHandsonAuxSpanish[0].name.split('.').length - 1]}`,
                    this.state.id);
            } else if (name == 'resultSpanish' && this.state.fileResultAuxSpanish.length > 0) {
                await this.state.AwsService.uploadfile(this.state.fileResultAuxSpanish[0],
                    `result_es.${this.state.fileResultAuxSpanish[0].name.split('.')[this.state.fileResultAuxSpanish[0].name.split('.').length - 1]}`,
                    this.state.id);
            }
            await this.getS3Files();

        } catch (err) {
            console.log(err)
        }
    }

    render() {
        return (
            <div className="content-card">
                <div className="centered-title">
                    <h1>Edição de oficina</h1>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em português:<span className="highlighter">*</span></label>
                                        <input type="text" name="namePortuguese" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.namePortuguese} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em inglês:<span className="highlighter">*</span></label>
                                        <input type="text" name="nameEnglish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.nameEnglish} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em espanhol:<span className="highlighter">*</span></label>
                                        <input type="text" name="nameSpanish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.nameSpanish} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Descrição em português:<span className="highlighter">*</span></label>
                                        <input type="text" name="descriptionPortuguese" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.descriptionPortuguese} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Descrição em inglês:<span className="highlighter">*</span></label>
                                        <input type="text" name="descriptionEnglish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.descriptionEnglish} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Descrição em espanhol:<span className="highlighter">*</span></label>
                                        <input type="text" name="descriptionSpanish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.descriptionSpanish} />
                                    </div>
                                </div>

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Apresentação em português<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileApresentationPortuguese.length > 0 || this.state.fileApresentationPortuguese != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileApresentationPortuguese.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileApresentationAuxPortuguese" name="fileApresentationAuxPortuguese" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('apresentationPortuguese')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }
                                
                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Apresentação em inglês<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileApresentationEnglish.length > 0 || this.state.fileApresentationEnglish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileApresentationEnglish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileApresentationAuxEnglish" name="fileApresentationAuxEnglish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('apresentationEnglish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Apresentação em espanhol<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileApresentationSpanish.length > 0 || this.state.fileApresentationSpanish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileApresentationSpanish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileApresentationAuxSpanish" name="fileApresentationAuxSpanish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('apresentationSpanish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Mão na massa em português<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileHandsonPortuguese.length > 0 || this.state.fileHandsonPortuguese != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileHandsonPortuguese.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileHandsonAuxPortuguese" name="fileHandsonAuxPortuguese" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('handsonPortuguese')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Mão na massa em inglês<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileHandsonEnglish.length > 0 || this.state.fileHandsonEnglish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileHandsonEnglish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileHandsonAuxEnglish" name="fileHandsonAuxEnglish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('handsonEnglish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Mão na massa em espanhol<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileHandsonSpanish.length > 0 || this.state.fileHandsonSpanish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileHandsonSpanish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileHandsonAuxSpanish" name="fileHandsonAuxSpanish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('handsonSpanish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Resultados em português<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileResultPortuguese.length > 0 || this.state.fileResultPortuguese != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileResultPortuguese.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileResultAuxPortuguese" name="fileResultAuxPortuguese" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('resultPortuguese')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }
                                
                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Resultados em inglês<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileResultEnglish.length > 0 || this.state.fileResultEnglish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileResultEnglish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileResultAuxEnglish" name="fileResultAuxEnglish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('resultEnglish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className="col-md-12 mb-1 mt-3">
                                    <h5>
                                        Resultados em espanhol<span className="highlighter">*</span>
                                    </h5>
                                </div>
                                {
                                    this.state.fileResultSpanish.length > 0 || this.state.fileResultSpanish != '' ?
                                        <div className="col-md-12 mb-4">
                                            <table className="table table-hover table-striped table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Data de modificação</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        this.state.fileResultSpanish.map(file => {
                                                            return (
                                                                <tr>
                                                                    <td>{file.Key.split("/")[1]}</td>
                                                                    <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                                    <td className="text-center">
                                                                        <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                            <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="javascript:void(0)">
                                                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <input type="file" id="fileResultAuxSpanish" name="fileResultAuxSpanish" required accept="image/png"
                                                        onChange={this.selectFile.bind(this)} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <a className="button-full btn-file" onClick={() => this.upload('resultSpanish')}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-upload"></i>
                                                        </span> Enviar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="button-full btn-save" type="button"
                                        disabled={this.state.fileApresentation == '' || this.state.fileHandson == '' || this.state.fileResult == ''}
                                        onClick={() => this.edit()}>
                                        <i className="fa fa-save"></i> salvar edição
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkshopEdit;