import { ExcelReport } from "../../../services/excel.service"
import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-BR');

export const generateDefaultReport = (data) => {
    const excelReport = new ExcelReport()

    excelReport.setColumns([
        { title: "Nome", width: 20 },
        { title: "Tipo de instituição", width: 20 },
        { title: "Site", width: 20 },
        { title: "Telefone", width: 10 },
        { title: "País", width: 10 },
        { title: "Estado", width: 10 },
        { title: "Cidade", width: 20 },
        { title: "Únicas", width: 10 },
        { title: "Campanhas", width: 10 },
        { title: "Total de oficinas", width: 20 },
        { title: "Oficinas concluídas", width: 20 },
        { title: "Oficinas incompletas", width: 20 },
        { title: "Participantes", width: 20 },
        { title: "Atendimentos", width: 10 },
        { title: "Data cadastro instituição", width: 20 }
      ])

    
    data.forEach(item => {
        excelReport.addRow([
            { 
                column: 'Nome',
                value: item.name 
            },
            { 
                column: 'Tipo de instituição',
                value: item.type
            },
            {
                column: 'Site',
                value: item.website 
            },
            { 
                column: 'Telefone',
                value: item.phone
            },
            { 
                column: 'País',
                value: item.country
            },
            { 
                column: 'Estado',
                value: item.state
            },
            { 
                column: 'Cidade',
                value: item.city 
            },
            { 
                column: 'Únicas',
                value: item.works 
            },
            { 
                column: 'Campanhas',
                value: item.campaigns 
            },
            {
                column: 'Total de oficinas',
                value: item.totalWorks 
            },
            { 
                column: 'Oficinas concluídas',
                value: item.totalWorksCompleted 
            },
            { 
                column: 'Oficinas incompletas',
                value: item.totalWorks - item.totalWorksCompleted 
            },
            { 
                column: 'Participantes',
                value: item.totalParticipants 
            },
            { 
                column: 'Atendimentos',
                value: item.attendments 
            },
            { 
                column: 'Data cadastro instituição',
                value: item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : ' - ' 
            },
        ])
    })

    return excelReport.generate()

}