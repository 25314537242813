import requests from '../shared/agent';

const endpoint = '/trash';

const TrashService = {
    getAll: async () => {
        return await requests.get(endpoint);
    },
    changeDeleted: async (resource, id) => {
        return await requests.del(`/${resource}/${id}`);
    }
};

export default TrashService;