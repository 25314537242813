import { Route, Redirect } from "react-router-dom";
import React, { Component } from "react";
import { privateRoutesList } from "../constants/privateRoutesList";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
  
const PrivateRoutes = ({ isMaster }) => {

    return (
        <>
        {
            privateRoutesList.map(({ 
                path, 
                component: Component,
                exact = false,
                isMasterOnly = false,
            }) => {
                const props = { path, exact, component: Component }

                return isMasterOnly && !isMaster ? 
                null : <PrivateRoute key={path} {...props} />
            })
        }

        <Route 
            exact
            path={['/', '/login']}
            render={() => <Redirect to="/dashboard" />}
        />
        </>
    )
}

export default PrivateRoutes;