import requests from '../shared/agent';

const endpoint = '/quizzes';

const QuizService = {
    getAll: async () => {
        return await requests.get(endpoint)
    },
    getById: async(id) => {
        return await requests.get(`${endpoint}/${id}`);
    },
    create: async(quiz) => {
        return await requests.post(endpoint, quiz);
    },
    edit: async(id, quiz) => {
        return await requests.put(`${endpoint}/${id}`, quiz)
    },
    changeActive: async(id, status) => {
        return await requests.post(`${endpoint}/${id}/active`, status)
    },
    remove: async(id) => {
        return await requests.del(`${endpoint}/${id}`);
    }
}

export default QuizService 