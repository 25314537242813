import moment from 'moment';

const styleCells = (instructors) => {
    const columns = [
      {
        title: "Nome",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Email",
        width: { wch: 30 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Instituição",
        width: { wch: 40 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Data cadastro instrutor",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Data de Expiração da Licença",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Número da Licença",
        width: { wch: 40 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
      {
        title: "Status da Licença",
        width: { wch: 20 },
        style: {
          font: { bold: true, color: { rgb: "ffffff" } },
          fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        },
      },
    ];
    let data = instructors.map((item) => {
      return [
        { value: item.name ? item.name : " - " },
        { value: item.email ? item.email : " - " },
        { value: item.institution.name ? item.institution.name : " - " },
        {
          value: item.createdAt
            ? moment(item.createdAt).format("DD/MM/YYYY")
            : " - ",
        },
        {
          value: item.institution.licenseExpireIn
            ? moment(item.institution.licenseExpireIn).format("DD/MM/YYYY")
            : " - ",
        },
        {
          value: item.institution.license ? item.institution.license : " - ",
        },
        {
          value: item.institution.licenseActive === true ? "Ativa" : "Inativa",
        },
      ];
    });

    return [
      {
        columns,
        data,
      },
    ];
  }

  export default styleCells;