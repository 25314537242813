import requests from '../shared/agent';

const endpoint = '/statistics'

const StatisticService = {
  general: async (query) => {
    let queryString = '?';

    for (const key of Object.keys(query)) {
      if (!query[key]) { continue; }

      if (queryString !== '?')
        queryString += '&'

      queryString += `${key}=${query[key]}`;
    }

    return await requests.get(`${endpoint}${queryString}`);
  },
  participantsByGender: async (query) => {
    let queryString = '?';

    for (const key of Object.keys(query)) {
      if (!query[key]) { continue; }

      if (queryString !== '?')
        queryString += '&'

      queryString += `${key}=${query[key]}`;
    }

    return await requests.get(`${endpoint}/participants/gender${queryString}`);
  },
  countPowerAssignments: async (query) => {
    let queryString = '?';

    for (const key of Object.keys(query)) {
      if (!query[key]) { continue; }

      if (queryString !== '?')
        queryString += '&'

      queryString += `${key}=${query[key]}`;
    }

    return await requests.get(`${endpoint}/powers/assignments${queryString}`);
  },
  countMissionAssignments: async (query) => {
    let queryString = '?';

    for (const key of Object.keys(query)) {
      if (!query[key]) { continue; }

      if (queryString !== '?')
        queryString += '&'

      queryString += `${key}=${query[key]}`;
    }

    return await requests.get(`${endpoint}/missions/assignments${queryString}`);
  }
};

export default StatisticService;

