import React, { useEffect } from "react";

import "./EditPowersStudents.scss";

import Powers from "../../../../shared/helpers/powers.json";
import ParticipantService from "../../../../services/participant.service";

export const EditPowersStudents = ({
  workshop,
  participantData,
  onChangePower,
  currentClassCode,
  seedUserData
}) => {

  useEffect(() => {
    async function getAllParticipantsMissionInfo() {
      const participantsPowerInfo = await ParticipantService.getParticipantsPowerInfo(currentClassCode,
        workshop.workshop)

        seedUserData(participantsPowerInfo.participantsInfo, participantData)
    }

    getAllParticipantsMissionInfo()
  }, []);

  if (workshop.presenceList.length < 1) {
    return (
      <div className="non-existent-list-notice">
        <p>A lista de presença desta oficina ainda não foi realizada.</p>
      </div>
    );
  } else {
    const currentWorkshopId = workshop.workshop;

    const filteredPowers = participantData.map((p) =>
      p.workshops?.participant?.powers?.filter(
        (p) => p.workshop === currentWorkshopId
      )
    );

    const getParticipantsPowersIds = filteredPowers?.map((p) => {
      return p?.map((pw) => pw?.powerId);
    });

    const powersList = [];

    for (let powerIdArray of getParticipantsPowersIds) {
      for (let powerId of powerIdArray) {
        const powersData = Powers.find((p) => p._id === powerId);
        powersList.push(powersData);
      }
    }

    const presentStudents = participantData
      .map((student) => student)
      .filter((y) => y.workshops.presenceList.presence === true);

    const onChange = (e) => {
      onChangePower(e);
    };

    return (
      <>
        {workshop.presenceList.length > 0 ? (
          <div className="edit-powers-content">
            <h3>{workshop.pt.name}</h3>
            <ul>
              {presentStudents.map((student, index) => {
                const getPowerId = student.workshops.participant.powers
                  .filter((p) => p.workshop === currentWorkshopId)
                  .map((ck) => ck.powerId)[0];

                const relatedPowers = powersList.filter(
                  (p) => p._id === getPowerId
                );

                const slicedPowers =
                  relatedPowers.length >= 1 && relatedPowers.slice(0, 1);

                return (
                  <>
                    <div className="powers-list-content list">
                      <div>
                        <li key={index} className="student-name">
                          {student.workshops.participant?.name}
                        </li>
                        <small>
                          {student.workshops?.participant?.powers &&
                          slicedPowers ? (
                            <p>
                              {slicedPowers.map((power) => power?.pt?.name)}
                            </p>
                          ) : (
                            <p className="no-power">Nenhum poder atribuido</p>
                          )}
                        </small>
                      </div>
                      <div>
                        <select
                          defaultValue="Selecione uma opção"
                          onChange={(e) => onChange(e.target.value)}
                        >
                          <option hidden>Selecionar poder</option>
                          {Powers.map((power) => (
                            <>
                              <option
                                key={power._id}
                                value={JSON.stringify({
                                  powerId: power._id,
                                  classCode: student.code,
                                  participantId: student.workshops.participant._id,
                                  workshopId: workshop.workshop,
                                })}
                              >
                                {power.pt.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                );
              })}
            </ul>
          </div>
        ) : (
          <p>A lista de presença desta turma ainda não foi finalizada.</p>
        )}
      </>
    );
  }
};
