import requests from '../shared/agent';
import axiosClient from '../shared/axiosClient';

const endpoint = '/dashboard'

const DashboardService = {
    general: async () => {
        return await requests.get(endpoint)
    },
    getClasses: async () => {
        return await requests.get(`${endpoint}/classes/search`)
    },

    getWorkshopByWorkshopTypeId: (code, workshopTypeId) => requests.get(`${endpoint}/classes/${code}/workshops/${workshopTypeId}`),
    
    searchClasses: async(query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/classes/search${queryString}`)
    },
    getReport: async (query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/classes/report${queryString}`)
    },
    getAttributions: async (query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/classes/attributions${queryString}`)
    },
    attendance: async (query) => {
        let queryString = '?';

        for (const key of Object.keys(query)) {
            if (!query[key]) { continue; }
            
            if (queryString !== '?')
                queryString += '&'

            queryString += `${key}=${query[key]}`;
        }

        return await requests.get(`${endpoint}/attendance${queryString}`)
    },
    removeClass: async (code) => {
        return await requests.del(`/classes/${code}`)
    },
    getDatesToEdit: async(id) => {
        return await requests.get(`${endpoint}/classes/get-date?idClass=${id}`);
    },
    putDatesToEdit: async (data) => {
        return await requests.put(`${endpoint}/classes/edit-date`, data);
    },
    getPresenceListToEdit: async(id) => {
        return await requests.get(`${endpoint}/classes/get-presence-list/${id}`);
    },
    getParticipantsByClassCode: async(code) => {
        return await requests.get(`${endpoint}/classes/get-participants-by-class-code/${code}`);
    },
    deleteParticipantById: async(id) => {
        return await axiosClient.delete(`${endpoint}/classes/participant/${id}`);
    },
    saveNewParticipant: async(participant, code) => {        
        return await requests.put(`${endpoint}/classes/${code}/participant`, participant);
    }
};

export default DashboardService;