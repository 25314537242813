import moment from "moment"
import SecurityService from "../../services/security.service"

const ParticipantItem = ({
    item,
    gender,
    removeParticipant
}) => {
    return (
        <tr>
          <td>{item.name}</td>
          <td>{moment(item.born).format("DD/MM/YYYY")}</td>
          <td className='td-nickname'>{item.nickname}</td>
          <td>{gender}</td>
          <td>{item.favoriteColor}</td>
          <td>{item.favoritePet}</td>
          <td>{item.class}</td>
          <td>{item.IFAs}</td>
          <td>{item.powersParticipant.join(', ')}</td>
          <td>{item.missionParticipant.join(', ')}</td>
          <td>
            {SecurityService.isMaster() ?
              <a
                className="ml-3 btn-remove"
                href="javascript:void(0)"
                onClick={removeParticipant}
              >
                remover
              </a> : <></>
            }
          </td>
        </tr>
    )
}

export default ParticipantItem