export const menuData =  [
        {
            label: 'Dashboard',
            icon: 'fa-chart-line',
            link: '/dashboard'
        },
        {
            label: 'Estatísticas',
            icon: 'fa-chart-pie',
            link: '/statistics'
        },
        {
            label: 'Poderes',
            icon: 'fa-fist-raised',
            link: '/powers'
        },
        {
            label: 'Missões',
            icon: 'fa-bullseye',
            link: '/missions'
        },
        {
            label: 'Oficinas',
            icon: 'fa-layer-group',
            link: '/workshop'
        },
        {
            label: 'Quizzes',
            icon: 'fa-puzzle-piece',
            link: '/quizzes'
        },
        {
            label: 'Instrutores',
            icon: 'fa-users',
            link: '/instructor'
        },
        {
            label: 'Participantes',
            icon: 'fa-rocket',
            link: '/participant'
        },
        {
            label: 'Treinamento',
            icon: 'fa-graduation-cap',
            link: '/trainings'
        },
        {
            label: 'Instituições',
            icon: 'fa-university',
            link: '/institutions'
        },
        {
            label: 'Licenças',
            icon: 'fa-key',
            link: '/licenses'
        }
]