import { Component, createContext } from "react";
import LocalStorage from "../services/LocalStorage.service";
import { FilterService } from "../services/filter.service";

const AuthContext = createContext({
    state: {
        token: null,
        tokenExp: null,
        user: null
    }
});

class AuthProvider extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        token: LocalStorage.retrieve('token'),
        tokenExp: LocalStorage.retrieve('tokenExp'),
        user: LocalStorage.retrieve('user')
    }

    setContext = (newState, callback) => {

        for (const [key, value] of Object.entries(newState)) {
            LocalStorage.persist(key, value)
        }

        this.setState(state => ({ ...state, ...newState }), () => {
                if (typeof callback == 'function') callback()
        })
    }

    clearContext = () => {
        const emptyState = {}
        const stateKeys = Object.keys(this.state);

        for (const [key, _] of stateKeys) {
            emptyState[key] = null
        }

        LocalStorage.clear()
        this.setContext(emptyState)
    }

    getSavedData = () => {
        const newState = {}
        const stateKeys = Object.keys(this.state);
        const dataSaved = stateKeys.map(key => {
            const value = LocalStorage.retrieve(key)

            return [key, value]
        })

        for (const [key, value] of dataSaved) {
            newState[key] = value
        }

        this.setContext(newState);
    }

    componentDidMount() {
        if(!this.state.user) return

        const filterService = new FilterService(this);
        filterService.verifyIfTokenIsExpired();
    }

    render() {
        const state = this.state;
        const {
            setContext,
            clearContext
        } = this;

        return (
            <AuthContext.Provider
                value={{
                    state,
                    setContext,
                    clearContext
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export {
    AuthContext,
    AuthProvider
}