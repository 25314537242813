import requests from '../shared/agent';

const endpoint = '/classes';

const ClassService = {    
    editPresence: async (classCode, presenceList) => {
        return await requests.post(`${endpoint}/${classCode}/editpresence`, {classCode, presenceList});
    },
    participantsByClass: async (code) => {
        return await requests.get(`${endpoint}/${code}/participants`);
    },
    updateStudentPower: async (classCode, workshopId, data) => {
        return await requests.put(`${endpoint}/${classCode}/workshops/${workshopId}/powers`, data)
    },
    updateStudentMission: async (classCode, workshopId, data) => {
        return await requests.put(`${endpoint}/${classCode}/workshops/${workshopId}/missions`, data)
    },
    classExist: async (code) => {
        return await requests.get(`${endpoint}/${code}`);
    },
    fillPresence: async (code, presenceList) => {
        return await requests.post(`${endpoint}/${code}/presence`, presenceList);        
    },

    completeStep: async (code, workshop, step) => {
        return await requests.post(`${endpoint}/${code}/stepcomplete`, { workshop, step });
    },

    completeClass: async (classComplete) => {
        return await requests.post(`${endpoint}/complete`, classComplete);
    },

    getLastWorkshopOfCampaignClasses: async () => {
        return await requests.get(`${endpoint}/last-campaign`);
    }
};

export default ClassService;