import React, { Component } from 'react';
import './Training-create.scss';

import TrainingService from '../../../services/training.service';
import PowerService from '../../../services/power.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class TrainingNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            namePortuguese: '',
            nameEnglish: '',
            nameSpanish: '',
            contentsPortuguese: [],
            contentsEnglish: [],
            contentsSpanish: [],
            power: '',
            powers: []
        }
    }

    componentWillMount = async () => {
        const powers = await PowerService.getAll();
        await this.setState({ powers: powers })
    }

    handleInputChange = async (e) => {
        const target = e.target;

        await this.setState({
            [target.name]: target.value
        });
    }

    addContent = async () => {
        let contentsPortuguese = this.state.contentsPortuguese;
        contentsPortuguese.push({
            title: '',
            text: '',
            contentVideo: ''
        });

        let contentsEnglish = this.state.contentsEnglish;
        contentsEnglish.push({
            title: '',
            text: '',
            contentVideo: ''
        });

        let contentsSpanish = this.state.contentsSpanish;
        contentsSpanish.push({
            title: '',
            text: '',
            contentVideo: ''
        });


        await this.setState({
            contentsPortuguese: contentsPortuguese,
            contentsEnglish: contentsEnglish,
            contentsSpanish: contentsSpanish,
        })
    }

    changeTitlePortuguese = async (e) => {
        const target = e.target;
        let aux = this.state.contentsPortuguese;
        aux[target.name].title = target.value;
        await this.setState({ contentsPortuguese: aux });
    }

    changeTextPortuguese = async (e) => {
        const target = e.target;
        let aux = this.state.contentsPortuguese;
        aux[target.name].text = target.value;
        await this.setState({ contentsPortuguese: aux });
    }

    changeVideoPortuguese = async (e) => {
        const target = e.target;
        let auxPT = this.state.contentsPortuguese;
        auxPT[target.name].contentVideo = target.value;

        let auxEN = this.state.contentsEnglish;
        auxEN[target.name].contentVideo = target.value;
        await this.setState({
            contentsPortuguese: auxPT,
            contentsEnglish: auxEN,
        });
    }

    changeTitleEnglish = async (e) => {
        const target = e.target;
        let aux = this.state.contentsEnglish;
        aux[target.name].title = target.value;
        await this.setState({ contentsEnglish: aux });
    }

    changeTextEnglish = async (e) => {
        const target = e.target;
        let aux = this.state.contentsEnglish;
        aux[target.name].text = target.value;
        await this.setState({ contentsEnglish: aux });
    }

    changeTitleSpanish = async (e) => {
        const target = e.target;
        let aux = this.state.contentsSpanish;
        aux[target.name].title = target.value;
        await this.setState({ contentsSpanish: aux });
    }

    changeTextSpanish = async (e) => {
        const target = e.target;
        let aux = this.state.contentsSpanish;
        aux[target.name].text = target.value;
        await this.setState({ contentsSpanish: aux });
    }

    removeContent = async (i) => {
        let contentsPortuguese = this.state.contentsPortuguese;
        let contentsEnglish = this.state.contentsEnglish;
        let contentsSpanish = this.state.contentsSpanish;

        contentsPortuguese.splice(i, 1);
        contentsEnglish.splice(i, 1);
        contentsSpanish.splice(i, 1);

        await this.setState({
            contentsPortuguese: contentsPortuguese,
            contentsEnglish: contentsEnglish,
            contentsSpanish: contentsSpanish
        })
    }

    showAlert(textButton, icon, title) {
        MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>{textButton}</span>,
            icon: icon,
            title: <p>{title}</p>
        })
    }

    create = async () => {
        let contentPortugueseInvalid = this.state.contentsPortuguese.filter(content => content.title == '' || content.text == '');
        let contentEnglishInvalid = this.state.contentsEnglish.filter(content => content.title == '' || content.text == '');
        let contentSpanishInvalid = this.state.contentsSpanish.filter(content => content.title == '' || content.text == '');

        if (this.state.namePortuguese == '' || this.state.nameEnglish == '' || this.state.nameSpanish == ''
            || contentPortugueseInvalid.length > 0
            || contentEnglishInvalid.length > 0
            || contentSpanishInvalid.length > 0
        ) {
            this.showAlert('OK', 'error', 'Há campos obrigatórios que devem ser preenchidos');
        } else if (this.state.contentsPortuguese.length < 1) {
            this.showAlert('OK', 'error', 'O treinamento deve ter ao menos um conteúdo');
        } else {

            let videoIntro = this.state.contentsPortuguese[0].contentVideo
            let training = {
                pt: {
                    name: this.state.namePortuguese,
                    video: videoIntro,
                    contents: this.state.contentsPortuguese
                },
                en: {
                    name: this.state.nameEnglish,
                    video: videoIntro,
                    contents: this.state.contentsEnglish
                },
                es: {
                    name: this.state.nameSpanish,
                    video: videoIntro,
                    contents: this.state.contentsSpanish
                }
            }

            delete training.pt.contents[0].contentVideo
            delete training.en.contents[0].contentVideo
            delete training.es.contents[0].contentVideo

            try {
                let res = await TrainingService.create(training);
                if (res._id) {
                    MySwal.fire({
                        confirmButtonColor: '#87BF40',
                        confirmButtonText: <span>OK</span>,
                        icon: 'success',
                        title: <p>Treinamento cadastrado com sucesso</p>
                    })
                    this.props.history.push('/trainings')
                }
            } catch (err) {
                console.log(err)
                MySwal.fire({
                    confirmButtonColor: '#87BF40',
                    confirmButtonText: <span>Tentar novamente</span>,
                    icon: 'error',
                    title: <p>Erro ao cadastrar treinamento.</p>
                })
            }
        }
    }

    render() {
        return (
            <div className="content-card">
                <div className="row page-title">
                    <h1>Novo treinamento</h1>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em português:<span className="highlighter">*</span></label>
                                        <input type="text" name="namePortuguese" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.namePortuguese} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em inglês:<span className="highlighter">*</span></label>
                                        <input type="text" name="nameEnglish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.nameEnglish} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Nome em espanhol:<span className="highlighter">*</span></label>
                                        <input type="text" name="nameSpanish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.nameSpanish} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="col-md-12">
                                <label className="content">Conteúdos:<span className="highlighter">*</span></label>
                                <a href="javascript:void(0)" className="new-content" onClick={() => this.addContent()}>
                                    <i className="fa fa-plus"></i> Novo conteúdo
                                </a>
                                <div>
                                    {
                                        this.state.contentsPortuguese.map((content, index) =>
                                            <div className="row" key={index}>
                                                <label>Titulo em português {index + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.title}
                                                    name={index}
                                                    onChange={this.changeTitlePortuguese} />
                                                <label>Texto em português {index + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.text}
                                                    name={index}
                                                    onChange={this.changeTextPortuguese} />
                                                <label>Video {index + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.contentVideo}
                                                    name={index}
                                                    onChange={this.changeVideoPortuguese} />
                                                <span className="remove-content"
                                                    onClick={() => this.removeContent(index)}
                                                >
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                                <hr className="separator" />
                                <div>
                                    {
                                        this.state.contentsEnglish.map((content, i) =>
                                            <div className="row" key={i}>
                                                <label>Titulo em inglês {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.title}
                                                    name={i}
                                                    onChange={this.changeTitleEnglish} />
                                                <label>Texto em inglês {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.text}
                                                    name={i}
                                                    onChange={this.changeTextEnglish} />
                                                <label>Video {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.contentVideo}
                                                    name={i}
                                                    disabled={true} />
                                                <span className="remove-content"
                                                    onClick={() => this.removeContent(i)}
                                                >
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                                <hr className="separator" />
                                <div>
                                    {
                                        this.state.contentsSpanish.map((content, i) =>
                                            <div className="row" key={i}>
                                                <label>Titulo em espanhol {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.title}
                                                    name={i}
                                                    onChange={this.changeTitleSpanish} />
                                                <label>Texto em espanhol {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.text}
                                                    name={i}
                                                    onChange={this.changeTextSpanish} />
                                                <label>Video {i + 1}:</label>
                                                <input className="form-control" type="text"
                                                    value={content.contentVideo}
                                                    name={i}
                                                    disabled={true} />
                                                <span className="remove-content"
                                                    onClick={() => this.removeContent(i)}
                                                >
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>




                            <div className="row row-center">
                                <div className="col-8">
                                    <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                                </div>
                                <div className="col-md-4 text-right">
                                    <button
                                        className="button-full btn-save"
                                        type="button"
                                        onClick={() => this.create()}
                                    >
                                        <i className="fa fa-check"></i> Cadastrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default TrainingNew;