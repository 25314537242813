import { React, useState, useEffect } from "react";
import "./EditMissionsStudents.scss";
import Powers from "../../../../shared/helpers/powers.json";
import Missions from "../../../../services/mission.service";
import ParticipantService from "../../../../services/participant.service";

export const EditMissionsStudents = ({
  workshop,
  participantData,
  onChangeMission,
  currentClassCode,
  seedUserData
}) => {
  const [missions, setMissions] = useState(null);

  useEffect(() => {
    if (!missions) {
      async function getAllMissions() {
        const allMissions = await Missions.getAll();
        setMissions(allMissions);
      }

      async function getAllParticipantsMissionInfo() {
        const participantsMissionInfo = await ParticipantService.getParticipantsMissionInfo(currentClassCode,
          workshop.workshop)

          seedUserData(participantsMissionInfo.participantsInfo, participantData)
      }

      getAllParticipantsMissionInfo()
      getAllMissions();
    }
  }, []);

  if (!workshop.powersCompleted) {
    return (
      <div className="non-existent-list-notice">
        <p>Os poderes desta oficina ainda não foram atribuídos.</p>
      </div>
    );
  } else {
    const currentWorkshopId = workshop.workshop;

    const filteredPowers = participantData.map((p) =>
      p.workshops?.participant?.powers?.filter(
        (p) => p.workshop === currentWorkshopId
      )
    );

    const getParticipantsPowersIds = filteredPowers?.map((p) => {
      return p?.map((pw) => pw?.powerId);
    });

    const powersList = [];

    for (let powerIdArray of getParticipantsPowersIds) {
      for (let powerId of powerIdArray) {
        const powersData = Powers.find((p) => p._id === powerId);
        powersList.push(powersData);
      }
    }

    const studentsWithPower = [];

    for (let part of participantData) {
      if(part.workshops.presenceList.presence === true) {
        for (let power of part.workshops.participant.powers) {
          if (power.workshop === currentWorkshopId) {
            studentsWithPower.push(part);
          }
        }
      } 
    }

    const filteredMissions = participantData.map((p) =>
      p.workshops?.participant?.missions?.filter(
        (p) => p.workshop === currentWorkshopId
      )
    );

    const getParticipantsMissionsIds = filteredMissions?.map((p) => {
      return p?.map((mission) => mission?.missionId);
    });

    const missionsList = [];

    for (let missionIdArray of getParticipantsMissionsIds) {
      for (let missionId of missionIdArray) {
        const missionsData = missions?.find((p) => p._id === missionId);
        missionsList.push(missionsData);
      }
    }

    return (
      <>
        {workshop.presenceList.length > 0 ? (
          <div className="edit-powers-content">
            <h3>{workshop.pt.name}</h3>
            <ul>
              {studentsWithPower.map((student, index) => {
                const getPowerId = student.workshops.participant.powers
                  .filter((p) => p.workshop === currentWorkshopId)
                  .map((ck) => ck.powerId)[0];

                const relatedPowers = powersList.filter(
                  (p) => p._id === getPowerId
                );

                const slicedPowers =
                  relatedPowers.length >= 1 && relatedPowers.slice(0, 1);

                const getMissionId = student.workshops.participant.missions
                  .filter((p) => p.workshop === currentWorkshopId)
                  .map((ck) => ck.missionId)[0];

                const relatedMissions = missionsList.filter(
                  (p) => p?._id === getMissionId
                );

                const slicedMissions =
                  relatedMissions.length >= 1 && relatedMissions.slice(0, 1);

                const missionsByPowers = missions?.filter(
                  (mission) => mission?.power === getPowerId
                );

                return (
                  <>
                    <div className="powers-list-content list">
                      <div>
                        <li key={index} className="student-name">
                          {student.workshops.participant?.name}
                        </li>
                        <small>
                          {student.workshops?.participant?.powers &&
                          slicedPowers ? (
                            <p>
                              Poder:{" "}
                              {slicedPowers.map((power) => power?.pt?.name)}
                            </p>
                          ) : (
                            <p className="no-power">Nenhum poder atribuido</p>
                          )}
                        </small>
                        <small>
                          {student.workshops?.participant?.missions &&
                          slicedMissions ? (
                            <p>
                              Missão:{" "}
                              {slicedMissions.map(
                                (mission) => mission?.pt?.name
                              )}
                            </p>
                          ) : (
                            <p className="no-power">Nenhuma missão atribuida</p>
                          )}
                        </small>
                      </div>
                      <div>
                        <select
                          defaultValue="Selecione uma opção"
                          onChange={(e) => onChangeMission(e.target.value)}
                        >
                          <option hidden>Selecionar missão</option>
                          {missionsByPowers?.map((mission) => (
                            <>
                              <option
                                key={mission._id}
                                value={JSON.stringify({
                                  missionId: mission._id,
                                  classCode: student.code,
                                  participantId: student.workshops.participant._id,
                                  workshopId: workshop.workshop,
                                })}
                              >
                                {mission.pt.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                );
              })}
            </ul>
          </div>
        ) : (
          <p>A lista de presença desta turma ainda não foi finalizada.</p>
        )}
      </>
    );
  }
};
