import React, { Component } from 'react';
import './Institution.scss';
import moment from 'moment'
import 'moment/locale/pt-br'

import InstitutionService from '../../services/institution.service';

import Pagination from "react-js-pagination";

import ReactExport from "react-data-export";
import DashboardService from '../../services/dashboard.service';
import Loading from '../Loading/Loading';
import { generateDefaultReport } from './utils/generateReport';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

moment.locale('pt-BR');

class Institution extends Component {
    constructor() {
        super()
        this.state = {
            searchDateStart: '',
            searchDateEnd: '',
            institutions: [],
            institutionsExport: [],
            date: [],
            powers: [],
            missions: [],

            skip: 0,
            limit: 50,
            totalItens: 0,
            itemsPerPage: [20, 50, 100],
            currentPage: 1,
            loading: true
        }
    }

    handleInputChange = async (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value
        });
    }

    componentDidMount = async () => {
        await this.search(1, true);
    }

    renderButtonExportExcel() {
        if (this.state.institutions) {
            return (
                <ExcelFile
                    element={
                        <button className="button-full btn-export m-right">
                            <i className="fa fa-file-excel"></i> exportar
                        </button>
                    }
                    filename={"instituições"}
                >
                    <ExcelSheet 
                        dataSet={generateDefaultReport(this.state.institutionsExport)} 
                        name="Instituições"
                    ></ExcelSheet>
                </ExcelFile>
            );
        }
    }

    handlePageChange = async (pageNumber) => {
        await this.setState({
            currentPage: pageNumber
        });

        await this.search(pageNumber)
    }


    existsPresence = (list) => {
        return list.some(item => item.name);
    }

    renderInstitutions() {
        let institutions = []
        institutions = this.state.institutions.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.name ? item.name : '-'}</td>
                    <td>{item.website ? item.website : '-'}</td>
                    <td>{item.phone ? item.phone : '-'}</td>
                    <td>{item.country ? item.country : '-'}</td>
                    <td>{item.state ? item.state : '-'}</td>
                    <td>{item.city ? item.city : '-'}</td>
                    <td>{item.works}</td>
                    <td>{item.campaigns}</td>
                    <td>{item.totalWorks}</td>
                    <td>{item.totalWorksCompleted}</td>
                    <td>{item.totalWorks - item.totalWorksCompleted}</td>
                    <td>{item.totalParticipants}</td>
                    <td>{item.attendments}</td>
                </tr>
            )
        })
        return institutions;
    }

    search = async (page, toexport = false) => {
        await this.setState({
            currentPage: page ? page : 1,
            skip: (page - 1) * this.state.limit,
            loading: true
        });

        let searchInstitution = {};
        Object.assign(searchInstitution, this.state);

        let institutions;
        let institutionsExport;
        if (toexport) {
            institutionsExport = this.buildData(await DashboardService.getReport({
                start: searchInstitution.searchDateStart,
                end: searchInstitution.searchDateEnd
            }));
        }


        searchInstitution.skip = (page - 1) * this.state.limit;
        institutions = await InstitutionService.searchInstitution(searchInstitution);

        institutions.result = this.buildData(institutions);


        await this.setState({
            institutions: institutions.result,
            totalItens: institutions.count,
            institutionsExport,
            loading: false
        })
    }


    buildData = (institutions) => {
        let institutionsAux = [];
        institutions.result.forEach(i => {
            if(!i?.instructor?.institution) return

            const index = institutionsAux.findIndex(iAux => iAux.name.toUpperCase() === i.instructor.institution.name.toUpperCase())
            if (index > -1) {
                //ja existe uma instituição com mesmo nome
                let attendments = 0;
                i.workshops.forEach(wk => {
                    wk.presenceList.forEach(presence => {
                        if (presence.presence) attendments++;
                    })
                })
                attendments += institutionsAux[index].attendments;
                let totalWorks = i.workshops.length + institutionsAux[index].totalWorks;
                let totalWorksCompleted = i.workshops.filter(w => this.existsPresence(w.presenceList)).length + institutionsAux[index].totalWorksCompleted;
                let totalParticipants = i.participants.some(item => item.name) ? (i.participants.length + institutionsAux[index].totalParticipants) : institutionsAux[index].totalParticipants;

                institutionsAux[index] = {
                    works: i.type === 'workshop' ? institutionsAux[index].works++ : institutionsAux[index].works,
                    campaigns: i.type === 'campaign' ? institutionsAux[index].campaigns++ : institutionsAux[index].campaigns,
                    ...institutionsAux[index],
                    totalWorks: totalWorks,
                    totalWorksCompleted: totalWorksCompleted,
                    totalParticipants: totalParticipants,
                    attendments: attendments,
                }
            } else {
                //nao é igual
                let attendments = 0;
                i.workshops.forEach(wk => {
                    wk.presenceList.forEach(presence => {
                        if (presence.presence) attendments++;
                    })
                })

                institutionsAux.push({
                    name: i.instructor.institution.name,
                    type: i.instructor.institution.type,
                    website: i.instructor.institution.website,
                    phone: i.instructor.institution.phone,
                    country: i.instructor.institution.address.country,
                    state: i.instructor.institution.address.state,
                    city: i.instructor.institution.address.city,
                    createdAt: i.instructor.createdAt,
                    works: i.type === 'workshop' ? 1 : 0,
                    campaigns: i.type === 'campaign' ? 1 : 0,
                    totalWorks: i.workshops.length,
                    totalWorksCompleted: i.workshops.filter(w => this.existsPresence(w.presenceList)).length,
                    totalParticipants: i.participants.some(item => item.name) ? i.participants.length : 0,
                    attendments: attendments
                })
            }
        });

        return institutionsAux
    }

    render() {
        return (
            <div className="content-card">
                <div className="row page-title">
                    <h1>Instituições</h1>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Data inicial:</label>
                            <input type="date" class="form-control" name="searchDateStart"
                                onChange={this.handleInputChange} value={this.state.searchDateStart} />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Data final:</label>
                            <input type="date" class="form-control" name="searchDateEnd"
                                onChange={this.handleInputChange} value={this.state.searchDateEnd} />
                        </div>
                    </div>

                    <div className="col-sm-12 text-right">
                        {this.renderButtonExportExcel()}
                        {/* <button
                            className="button-full btn-export m-right"
                            onClick={() => this.export()}>
                            <i className="fa fa-file-excel"></i> exportar
                        </button> */}

                        <button
                            className="button-full btn-save"
                            onClick={() => this.search(1, true)}
                            type="button"
                        >
                            <i className="fa fa-search"></i> Pesquisar
                        </button>
                    </div>
                </div>

                <div className="row content-header">
                    <h3 className="list-title">Lista de instituições</h3>
                </div>

                <Loading status={this.state.loading}>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-responsive table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Site</th>
                                        <th>Telefone</th>
                                        <th>País</th>
                                        <th>Estado</th>
                                        <th>Cidade</th>
                                        {/* <th>Tipo</th> */}
                                        <th>Únicas</th>
                                        <th>Campanhas</th>
                                        <th>Total de oficinas</th>
                                        <th>Oficinas concluídas</th>
                                        <th>Oficinas incompletas</th>
                                        <th>Participantes</th>
                                        <th>Atendimentos</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.renderInstitutions()}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="pagination-content">
                        <Pagination
                            hideFirstLastPages
                            pageRangeDisplayed={10}
                            activePage={this.state.currentPage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.totalItens }
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                </Loading>

            </div>
        )
    }
}

export default Institution;