import React, { useState, useEffect } from "react";
import Participants from "../../../../services/participant.service";
import ClassService from "../../../../services/class.service";

export const AttributePresenceListStudents = ({ workshop, finishPresenceList }) => {
  const [localWorkshop, setLocalWorkshop] = useState(workshop);
  const [studentsList, setstudentsList] = useState([]);
  const [participants, setParticipants] = useState([]);
  const currentClass = JSON.parse(window.localStorage.getItem("currentClass"));
  
  useEffect(() => {
    if (studentsList !== []) {
      async function getStudentsList() {
        const students = await Participants.getByClass(currentClass.code);
        setstudentsList(students);
      }
      getStudentsList();
    }
  }, []);
  
  useEffect(() => {
    async function setPresenceToTrue() {
      const classCode = await ClassService.classExist(currentClass.code);

      let exitsWK = classCode.workshops.filter(w => w.workshop == workshop.workshop);

      let students = await ClassService.participantsByClass(currentClass.code);
      students = students.map(participant => {
        if(exitsWK.length && exitsWK[0].presenceList.length){
          let aux = exitsWK[0].presenceList.find(p => p._id == participant._id)
          if(aux){
            participant.presence = aux.presence.toString();
          } else {
            participant.presence = "true";
          } 
        } else {
          participant.presence = "true";
        }        
        return participant;
      })

    await setstudentsList(students)
    }
    setPresenceToTrue()
  }, []);

  const presenceList = {
    workshop: workshop.workshop,
    participants: studentsList
  }
  
  async function savePresence() {
    await ClassService.fillPresence(currentClass.code, presenceList);
    await ClassService.completeStep(currentClass.code, workshop.workshop, 'presenceCompleted');
  }

  finishPresenceList(savePresence);

  const changePresence = async (e) => {
    const id = e.target.id.split('-')[0];
    const value = e.target.value;    

    const index = studentsList.findIndex(p => p._id === id);
    studentsList[index] = { ...studentsList[index], presence: value }

    await setParticipants({
      participants: studentsList
    })

    const presenceList = {
      workshop: workshop.workshop,
      participants: studentsList
    }
    
    await ClassService.fillPresence(currentClass.code, presenceList);
    await ClassService.completeStep(currentClass.code, workshop.workshop, 'presenceCompleted');
  }
 
  return (
    <ul className="edit-presence-list-content">
      <h3>{localWorkshop.pt.name}</h3>
      {studentsList?.map((student, index) => {
        return (
          <li key={index} className="list">
            <label style={ { maxWidth: "20ch" } }>{student.name}</label>
            <div>
              <form className="presenca-input-radio">
                <input
                  id={`${student._id}-presenca`}
                  name="lista-presenca"
                  type="radio"
                  value="true"
                  checked={student.presence === 'true'}                    
                  onChange={(e) =>
                    changePresence(e)
                  }
                />
                <label
                  className="presenca"
                  htmlFor={`${student._id}-presenca`}
                >
                  Presença
                </label>

                <input
                  id={`${student._id}-falta`}
                  name="lista-presenca"
                  type="radio"
                  value="false"
                  checked={student.presence === 'false'}
                  onChange={(e) => changePresence(e)}
                />
                <label className="falta" htmlFor={`${student._id}-falta`}>
                  Falta
                </label>
              </form>
            </div>
          </li>
        );
      })}
    </ul>
  );
}