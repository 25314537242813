import ReactTooltip from 'react-tooltip';
import { AiOutlineQuestionCircle } from "react-icons/ai";

const anchorStyle = {
    marginLeft: '5px'
}

const FilterDetails = ({ name, message }) => {
    return (
        <>
            <a 
                data-tip 
                data-for={name}
                style={anchorStyle}
            >
                <AiOutlineQuestionCircle />
            </a>
            <ReactTooltip
                id={name}
                place="top"
                effect="solid"
            >
                <span>{message}</span>
            </ReactTooltip>
        </>
    )
}

export default FilterDetails;