import React from "react";
import "./EditStarsStudents.scss";

export const EditStarsStudents = ({ workshop, participants, onChangeStar }) => {
  if (!workshop.missionsCompleted) {
    return (
      <div className="non-existent-list-notice">
        <p>As missões desta oficina ainda não foram atribuídas.</p>
      </div>
    );
  }

  if (!workshop.presenceList.length) {
    return <p>A lista de presença desta turma ainda não foi finalizada.</p>;
  }

  const getStarType = (student) => {
    const studentsStar = student.stars.find(
      (p) => p.workshop === workshop.workshop
    );
    return studentsStar?.type;
  };

  return (
    <div className="edit-stars-content">
      <h3>{workshop.pt.name}</h3>
      <ul>
        {participants.map((student) => {
          const starsType = getStarType(student);

          return (
            <>
              <div className="stars-list-content list">
                <div>
                  <li key={student._id} className="student-name">{student.name}</li>
                  <small>
                    {starsType === "1" ? (
                      <p>Estrela completa</p>
                    ) : starsType === "2" ? (
                      <p>Estrela incompleta</p>
                    ) : (
                      <p>Sem estrela</p>
                    )}
                  </small>
                </div>
                <div>
                  <select
                    defaultValue="Selecione uma opção"
                    onChange={(e) =>
                      onChangeStar({ ...student, star: e.target.value })
                    }
                  >
                    <option selected="selected">Selecionar opção</option>

                    {starsType === "0" ? (
                      <option disabled>Sem estrela</option>
                    ) : (
                      <option value={0}>Sem estrela</option>
                    )}
                    <option value={2}>Estrela incompleta</option>
                    <option value={1}>Estrela completa</option>
                  </select>
                </div>
              </div>
            </>
          );
        })}
      </ul>
    </div>
  );
};
