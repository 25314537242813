const workshops = [
  {name: "Minha Própria Casa", value: 1},
  {name: "Transporte do Futuro", value: 2},
  {name: "Torre de Papel", value: 3},
  {name: "Show de Talentos", value: 4},
  {name: "Luz, Câmera, Ação", value: 5},
  {name: "Mão na Massa", value: 6},
  {name: "Quebrando a Cabeça", value: 7},
  {name: "A Quem Eu Admiro", value: 8}
]

export default workshops;