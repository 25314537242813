import moment from "moment";

const buildGeneralDataRows = function () {
  let dump = [];

  let general = this.state.generalData;
  let filtered = this.state.generalFilteredData;

  const endDate = this.state.dateEnd
    ? this.state.dateEnd
    : moment().utc().format("YYYY-MM-DD");

  dump.push(
    Array.of(
      "Oficinas aplicadas",
      filtered.completedWorkshops,
      general.completedWorkshops
    )
  );

  // Modo campanha só aparece até a data do último workshop pertencente à uma turma do tipo campanha
  if (moment(this.state.dateStart).isBefore(this.state.lastCampaignClassWorkshopDateTime)) {
    dump.push(
      Array.of(
        "Crianças participantes (Campanha)",
        filtered.campaignByType.campaign.participants,
        general.campaignByType.campaign.participants
      )
    );
    dump.push(
      Array.of(
        "Atendimentos realizados (Campanha)",
        filtered.campaignByType.campaign.attendances,
        general.campaignByType.campaign.attendances
      )
    );
  }

  // última turma do tipo "workshop" (oficina única) foi criada em 07/05/2022
  if (moment(this.state.dateStart).isBefore(this.limitDateWorkshops)) {
    dump.push(
      Array.of(
        "Crianças participantes (Oficina única)",
        filtered.campaignByType.workshop.participants,
        general.campaignByType.workshop.participants
      )
    );
    dump.push(
      Array.of(
        "Atendimentos realizados (Oficina única)",
        filtered.campaignByType.workshop.attendances,
        general.campaignByType.workshop.attendances
      )
    );
  }

  // modo aprendiz (campaignLevel1) e modo especialista (campaignLevel2)
  // passaram a existir após a exclusão do workshop (oficina única)
  if (moment(endDate).isAfter(this.limitDateWorkshops)) {
    dump.push(
      Array.of(
        "Crianças participantes (Modo Aprendiz)",
        filtered.campaignByType.campaignLevel1.participants,
        general.campaignByType.campaignLevel1.participants
      )
    );
    dump.push(
      Array.of(
        "Atendimentos realizados (Modo Aprendiz)",
        filtered.campaignByType.campaignLevel1.attendances,
        general.campaignByType.campaignLevel1.attendances
      )
    );
    dump.push(
      Array.of(
        "Crianças participantes (Modo Especialista)",
        filtered.campaignByType.campaignLevel2.participants,
        general.campaignByType.campaignLevel2.participants
      )
    );
    dump.push(
      Array.of(
        "Atendimentos realizados (Modo Especialista)",
        filtered.campaignByType.campaignLevel2.attendances,
        general.campaignByType.campaignLevel2.attendances
      )
    );
  }
  dump.push(
    Array.of(
      "Total de atendimentos",
      filtered.totalAttendances,
      general.totalAttendances
    )
  );
  dump.push(
    Array.of(
      "Poderes atribuídos",
      filtered.assignedPowers,
      general.assignedPowers
    )
  );
  dump.push(
    Array.of(
      "Missões atribuídas",
      filtered.assignedMissions,
      general.assignedMissions
    )
  );
  dump.push(
    Array.of(
      "Missões realizadas",
      filtered.completedMissions,
      general.completedMissions
    )
  );
  dump.push(
    Array.of(
      "Média de crianças por oficina",
      filtered.AveragePerWorkshop,
      general.AveragePerWorkshop
    )
  );
  dump.push(
    Array.of(
      "Treinamento feito no app (instrutores)",
      this.state.trainingCompletedFiltered,
      this.state.trainingCompleted
    )
  );

  return dump;
};

export { buildGeneralDataRows };
