const buildMissions = (powersSinceStartOfTheYear, powersFilteredByDate) => {

  const missionsSinceStartOfTheYear = powersSinceStartOfTheYear.reduce((missions, power) => [...missions, ...power.power.missions], []);
  const missionsFilteredByDate = powersFilteredByDate.reduce((missions, power) => [...missions, ...power.power.missions], []);

  const allMissions = [...new Set([...missionsSinceStartOfTheYear, ...missionsFilteredByDate])]
  
  const result = allMissions.map(mission => {

    const currentMissionInSinceStartOfTheYearArray = missionsSinceStartOfTheYear.find(m => m.name.pt == mission.name.pt);
    const currentMissionInFilteredByDateArray = missionsFilteredByDate.find(m => m.name.pt == mission.name.pt);

    const amountSinceStartOfTheYear = currentMissionInSinceStartOfTheYearArray?.amount || 0;
    const amountFilteredByDate = currentMissionInFilteredByDateArray?.amount || 0;


    return {
      ...mission,
      amount: {
        total: amountSinceStartOfTheYear + amountFilteredByDate,
        sinceStartOfTheYear: amountSinceStartOfTheYear,
        filteredByDate: amountFilteredByDate
      }
    }
  });

  return result
}

export { buildMissions }