import moment from "moment";
import 'moment/locale/pt-br';

export const missionsSheet = (missions, dateFiltered) => {

    const borderStyle = {
      style: "thin",
      color: "#000"
    }

    const border = {
      top: borderStyle,
      bottom: borderStyle,
      left: borderStyle,
      right: borderStyle
    }

    const styles = {
      header: {
        font: {
          name: "Calibri", sz: 11, bold: true,
          color: { rgb: "ffffff" }
        },
        fill: { patternType: "solid", fgColor: { rgb: "F69522" } },
        alignment: { vertical: "center", horizontal: "center" },
        border
      },

      cells: {
        font: { name: "Calibri", sz: 11 },
        alignment: { vertical: "center", horizontal: "center" },
        border
      },

      cellsPower: {
        font: { name: "Calibri", sz: 11 },
        alignment: { vertical: "center", horizontal: "center" },
        fill: { patternType: "solid", fgColor: { rgb: "FBCA90" } },
        border
      }
    }

    const columns = [
      {
        title: "Missões atribuídas",
        width: { wch: 40 },
        style: styles.header
      },
      {
        title: dateFiltered,
        width: { wch: 20 },
        style: styles.header
      },
      {
        title: moment().startOf('year').utc().format('DD/MM/YYYY'),
        width: { wch: 20 },
        style: styles.header
      },
    ];

    let totalSinceStartOfTheYear = missions.reduce((acc, mission) => acc + mission.amount.sinceStartOfTheYear,0);
    let totalFilteredByDate = missions.reduce((acc, mission) => acc + mission.amount.filteredByDate,0);

    const data = missions.map(element => 
        [{
          value: element.name.pt,
          style: styles.cells
        },
        {
          value: element.amount.filteredByDate,
          style: styles.cells
        },
        {
          value: element.amount.sinceStartOfTheYear,
          style: styles.cells
        },
      ]);

    data.push(Array.of(
      { 
        value: "Total",
        style: styles.cells
      },
      { 
        value: totalFilteredByDate, 
        style: styles.cells
      },
      { 
        value: totalSinceStartOfTheYear,
        style: styles.cells
      }
    ));

    return [
      {
        columns,
        data
      }
    ];
  }