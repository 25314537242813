import moment from "moment";
import React, { useState } from "react";
import { ParticipantInput } from "./ParticipantInput/ParticipantInput";
//import editButton from "../../../../../assets/images/icons/edit-button.png";
import removeButton from "../../../../assets/images/icons/remove-button.png";

import "./EditParticipants.scss";

import DashboardService from "../../../../services/dashboard.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const EditParticipants = ({ participants, name, onClick, turma }) => {
  const [participant, setParticipant] = useState({
    _id: "",
    name: "",
    born: "",
    gender: "",
  });
  const [participantByClass, setParticipantByClass] = useState(participants);
  const [studentId, setStudentId] = useState("");
  const error = "A turma deve ter no mínimo 5 alunos.";  

  function formatGender(gender) {
    let text = "";
    if (gender === "male") text = "Masculino";
    else if (gender === "female") text = "Feminino";
    else if (gender === "other") text = "Outros";
    return text !== "" ? `, ${text}` : "";
  }

  const removeParticipant = async (id) => {
    setStudentId(id);
    window.history.pushState({}, "", `/dashboard/classes/participant/${id}`);

    Swal.fire({
      title: "Tem certeza que quer excluir este participante?",
      text: "Você não poderá reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#E81E47",
      cancelButtonColor: "#fff",
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.history.pushState({}, "", `/dashboard`);
        const filteredParticipant = participantByClass.filter(
          (participant) => participant._id !== id
        );

        setParticipantByClass(filteredParticipant);

        try {
          await DashboardService.deleteParticipantById(id);
        } catch (err) {
          const errorMessage = err.response.data.message === "class has incompleted workshop"
            ? "Turma com oficina em andamento"
            : "Erro ao deletar participante";

          await MySwal.fire({
            confirmButtonColor: "#87BF40",
            confirmButtonText: <span>OK</span>,
            icon: "error",
            title: <p>{errorMessage}</p>,
          });
        }
        onClick(turma);

      } else {
        window.history.pushState({}, "", `/dashboard`);
        onClick(turma);
      }
    });
  };

  return (
    <>
      <h4 className="add-participant-title">{name}</h4>
      <h4 className="add-participant-label-title">Adicionar Participante</h4>

      <div className="form-group">
        <ParticipantInput
          onClick={onClick}
          turma={turma}
          participants={participants}
        />

        <p className="add-participant-label-title">
          Lista de Participantes ({participants.length})
        </p>

        <p className="exclude-all-participants-warning">
          {participantByClass.length > 5 ? "" : error}
        </p>

        <ul className="add-participant-participants-list">
          {participants.reverse().map((participant, index) => (
            <li key={index}>
              <div className="participants-info">
                <span className="participants-name">{`${
                  participant.name
                }${formatGender(participant.gender)}`}</span>
                <span className="participants-date">
                  {moment(participant.born).locale("pt-BR").format("L")}
                </span>
              </div>
              <div className="participants-buttons">
                {participantByClass.length > 5 ? (
                  <button onClick={() => removeParticipant(participant._id)}>
                    <img
                      src={removeButton}
                      alt=""
                      className={"participants-remove"}
                    />
                  </button>
                ) : (
                  <button>
                    <img
                      src={removeButton}
                      alt=""
                      className={"participants-remove-disabled"}
                    />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
