import Toggle from "react-toggle";
import SecurityService from "../../../../services/security.service";

const InstructorItem = ({ 
    item, 
    changeActive,
    navigate,
    removeInstructor
}) => {
    return (
        <tr>
          <td>{item.name}</td>
          <td>{item.email}</td>
          <td>{item.cpf}</td>
          <td>{item.institution ? item.institution.name : ""}</td>
          <th>{item.isTeacher ? "Sim" : "Não"}</th>
          <td>
            <Toggle
              id={item._id}
              defaultChecked={item.active}
              onChange={changeActive}
            />
          </td>
          <td>
            <a 
              className="btn-edit" 
              href={`/instructor/${item._id}`}
              onClick={navigate}
            >
              editar
            </a>
            {SecurityService.isMaster() &&
              <a
                className="ml-3 btn-remove"
                href="javascript:void(0)"
                onClick={removeInstructor}
              >
                remover
              </a>
            }
          </td>
        </tr>
      );
}

export default InstructorItem