import React, { Component } from 'react';
import './Quiz-edit.scss';

import QuizService from '../../../services/quiz.service';
import PowerService from '../../../services/power.service';
import TrainingService from '../../../services/training.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class QuizEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            type: '',
            training: '',
            statementPortuguese: '',
            curiosityPortuguese: '',
            answersPortuguese: [],
            statementEnglish: '',
            curiosityEnglish: '',
            answersEnglish: [],
            statementSpanish: '',
            curiositySpanish: '',
            answersSpanish: [],
            powers: [],
            trainings: []
        }
    }

    componentWillMount = async() => {
        const powers = await PowerService.getAll();
        const trainings = await TrainingService.getAll();

        let { id } = this.props.match.params;
        let quiz = await QuizService.getById(id);

        await this.setState({
            id: quiz._id,
            type: quiz.type,
            statementPortuguese: quiz.pt.statement,
            statementEnglish: quiz.en.statement,
            statementSpanish: quiz.es.statement,
            curiosityPortuguese: quiz.pt.curiosity ? quiz.pt.curiosity : '',
            curiosityEnglish: quiz.en.curiosity ? quiz.en.curiosity : '',
            curiositySpanish: quiz.es.curiosity ? quiz.es.curiosity : '',
            answersPortuguese: quiz.pt.answers,
            answersEnglish: quiz.en.answers,
            answersSpanish: quiz.es.answers,
            training: quiz.training,
            powers: powers,
            trainings: trainings
        })
    }

    handleInputChange = async (e) => {
        const target = e.target;

        await this.setState({
            [target.name]: target.value
        });

        if(target.name == 'type'){
            await this.setState({
                training: '',
                curiosityEnglish: '',
                curiosityPortuguese: '',
                curiositySpanish: ''
            })
        }

        console.log(this.state)
    }

    changeAlternativePortuguese = async(e) => {
        const target = e.target;
        let aux = this.state.answersPortuguese;
        aux[target.name].answer = target.value;
        await this.setState({ answersPortuguese: aux });
    }

    changeAlternativeEnglish = async(e) => {
        const target = e.target;
        let aux = this.state.answersEnglish;
        aux[target.name].answer = target.value;
        await this.setState({ answersEnglish: aux });
    }

    changeAlternativeSpanish = async(e) => {
        const target = e.target;
        let aux = this.state.answersSpanish;
        aux[target.name].answer = target.value;
        await this.setState({ answersSpanish: aux });
    }

    changeCorrect = async(e) => {
        const target = e.target;
        let auxPortuguese = this.state.answersPortuguese;
        auxPortuguese = auxPortuguese.map(a => {
            a.correct = false;
            return a;
        });
        const index = target.id.replace('alt-pt-', '')
        auxPortuguese[index].correct = true;

        let auxEnglish = this.state.answersEnglish;
        auxEnglish = auxEnglish.map(a => {
            a.correct = false;
            return a;
        });
        auxEnglish[index].correct = true;

        let auxSpanish = this.state.answersSpanish;
        auxSpanish = auxSpanish.map(a => {
            a.correct = false;
            return a;
        });
        auxSpanish[index].correct = true;

        await this.setState({
            answersPortuguese: auxPortuguese,
            answersEnglish: auxEnglish,
            answersSpanish: auxSpanish,
        });
    }

    addAlternative = async() => {
        let answersPortuguese = this.state.answersPortuguese;
        answersPortuguese.push({
            answer: '',
            correct: false
        });

        let answersEnglish = this.state.answersEnglish;
        answersEnglish.push({
            answer: '',
            correct: false
        });

        let answersSpanish = this.state.answersSpanish;
        answersSpanish.push({
            answer: '',
            correct: false
        });

        await this.setState({
            answersPortuguese: answersPortuguese,
            answersEnglish: answersEnglish,
            answersSpanish: answersSpanish,
        })
    }

    removeAlternative = async(i) => {
        let auxPortuguese = this.state.answersPortuguese;
        let answersEnglish = this.state.answersEnglish;
        let answersSpanish = this.state.answersSpanish;

        auxPortuguese.splice(i, 1);
        answersEnglish.splice(i, 1);
        answersSpanish.splice(i, 1);

        await this.setState({
            answersPortuguese: auxPortuguese,
            answersEnglish: answersEnglish,
            answersSpanish: answersSpanish,
        })
    }

    showAlert(textButton, icon, title){
        MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>{textButton}</span>,
            icon: icon,
            title: <p>{title}</p>
        })
    }

    edit = async() => {
        let alternativesPortugueseInvalid = this.state.answersPortuguese.filter(answer => answer.answer == '');
        let alternativesEnglishInvalid = this.state.answersEnglish.filter(answer => answer.answer == '');
        let alternativesSpanishInvalid = this.state.answersSpanish.filter(answer => answer.answer == '');

        let correct = this.state.answersPortuguese.filter(answer => answer.correct);
        if(this.state.statementPortuguese == '' || this.state.statementEnglish == '' || this.state.statementSpanish == ''
                                      || this.state.training == '' 
                                      || (this.state.type == 'participante' && (this.state.curiosityEnglish == '' || this.state.curiosityPortuguese == '' || this.state.curiositySpanish == ''))
                                      || alternativesPortugueseInvalid.length > 0
                                      || alternativesEnglishInvalid.length > 0
                                      || alternativesSpanishInvalid.length > 0){
            this.showAlert('OK', 'error', 'Há campos obrigatórios que devem ser preenchidos');
        } else if(this.state.answersPortuguese.length < 2){
            this.showAlert('OK', 'error', 'O quiz deve ter ao menos duas alternativas');
        } else if(correct.length == 0){
            console.log(this.state.answersPortuguese)
            this.showAlert('OK', 'error', 'Selecione a alternativa correta');
        } else {

            const quiz = {
                type: this.state.type,
                training: this.state.training,
                pt: {
                    curiosity: this.state.curiosityPortuguese,
                    statement: this.state.statementPortuguese,
                    answers: this.state.answersPortuguese
                },
                en: {
                    curiosity: this.state.curiosityEnglish,
                    statement: this.state.statementEnglish,
                    answers: this.state.answersEnglish
                },
                es: {
                    curiosity: this.state.curiositySpanish,
                    statement: this.state.statementSpanish,
                    answers: this.state.answersSpanish
                }
            }

            try{
                let res = await QuizService.edit(this.state.id, quiz);                
                if(res._id){
                    MySwal.fire({
                        confirmButtonColor: '#87BF40',
                        confirmButtonText: <span>OK</span>,
                        icon: 'success',
                        title: <p>Quiz atualizado com sucesso</p>
                    })
                    this.props.history.push('/quizzes')
                }
            } catch(err){
                console.log(err)
                MySwal.fire({
                    confirmButtonColor: '#87BF40',
                    confirmButtonText: <span>Tentar novamente</span>,
                    icon: 'error',
                    title: <p>Erro ao atualizar quiz.</p>
                })
            }
        }
    }

    render() {
        return (
            <div className="content-card">
                <div className="centered-title">
                    <h1>Edição de quiz</h1>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form>
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <div className="form-group">
                                        <label>Tipo do quiz:<span className="highlighter">*</span></label>
                                        <input type="radio" className="ml-3 mr-1" name="type" value="participante"
                                            onChange={this.handleInputChange}
                                            checked={this.state.type == 'participante'}
                                            />
                                        <label>Game</label>
                                        <input type="radio" className="ml-3 mr-1" name="type" value="instrutor"
                                            onChange={this.handleInputChange}
                                            checked={this.state.type == 'instrutor'}/>
                                        <label>Treinamento</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Poder:{ this.state.type == 'participante' ? <span className="highlighter">*</span> : null }</label>
                                        <select name="training" className="form-control" required 
                                            value={this.state.training}
                                            onChange={this.handleInputChange}
                                            disabled={this.state.type == 'instrutor'}
                                        >
                                            <option value="">Selecione o poder</option>
                                            {
                                                this.state.powers.map((item, index) => {
                                                    return(
                                                        <option value={item._id} key={index}>{item.pt.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Treinamento:{ this.state.type == 'instrutor' ? <span className="highlighter">*</span> : null }</label>
                                        <select name="training" className="form-control" required 
                                            value={this.state.training}
                                            onChange={this.handleInputChange}
                                            disabled={this.state.type == 'participante'}
                                        >
                                            <option value="">Selecione o Treinamento</option>
                                            {
                                                this.state.trainings.map((item, index) => {
                                                    return(
                                                        <option value={item._id} key={index}>{item.pt.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Enunciado em português:<span className="highlighter">*</span></label>
                                        <textarea name="statementPortuguese" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.statementPortuguese}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Enunciado em inglês:<span className="highlighter">*</span></label>
                                        <textarea name="statementEnglish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.statementEnglish}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Enunciado em espanhol:<span className="highlighter">*</span></label>
                                        <textarea name="statementSpanish" className="form-control" required
                                            onChange={this.handleInputChange} value={this.state.statementSpanish}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Curiosidade em português:{ this.state.type == 'participante' ? <span className="highlighter">*</span> : null }</label>
                                        <textarea name="curiosityPortuguese" className="form-control" required
                                            onChange={this.handleInputChange} 
                                            value={this.state.curiosityPortuguese}
                                            disabled={this.state.type == 'instrutor'}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Curiosidade em inglês:{ this.state.type == 'participante' ? <span className="highlighter">*</span> : null }</label>
                                        <textarea name="curiosityEnglish" className="form-control" required
                                            onChange={this.handleInputChange} 
                                            value={this.state.curiosityEnglish}
                                            disabled={this.state.type == 'instrutor'}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Curiosidade em espanhol:{ this.state.type == 'participante' ? <span className="highlighter">*</span> : null }</label>
                                        <textarea name="curiositySpanish" className="form-control" required
                                            onChange={this.handleInputChange} 
                                            value={this.state.curiositySpanish}
                                            disabled={this.state.type == 'instrutor'}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label>Alternativas:<span className="highlighter">*</span></label>
                                    <a href="javascript:void(0)" className="new-alternative" onClick={() => this.addAlternative()}>
                                        <i className="fa fa-plus"></i> Nova alternativa
                                    </a>
                                    <div>
                                        {
                                            this.state.answersPortuguese.map((answer, index) =>
                                                <div className="form-check form-group" key={index}>
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="alternative-pt"
                                                        id={`alt-pt-${index}`}
                                                        aux={index}
                                                        value={index}
                                                        checked={answer.correct}
                                                        onChange={this.changeCorrect}
                                                    />
                                                    <input className="form-control" type="text" value={answer.answer} name={index} onChange={this.changeAlternativePortuguese} />
                                                    <span className="remove-alternative" onClick={() => this.removeAlternative(index)}>
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </div>
                                            ) 
                                        }
                                    </div>
                                    <hr className="separator"/>
                                    <div>
                                        { 
                                            this.state.answersEnglish.map((answer, indice) =>
                                                <div className="form-check form-group" key={indice}>
                                                    <input className="form-check-input" 
                                                        type="radio"
                                                        disabled={true}
                                                        name="alternative-en"
                                                        id={`alt-en-${indice}`}
                                                        aux={indice}
                                                        value={indice}
                                                        checked={answer.correct}
                                                        onChange={this.changeCorrect}
                                                    />
                                                    <input className="form-control" type="text" value={answer.answer} name={indice} onChange={this.changeAlternativeEnglish} />
                                                    <span className="remove-alternative" onClick={() => this.removeAlternative(indice)}>
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </div>
                                            ) 
                                        }
                                    </div>
                                    <hr className="separator"/>
                                    <div>
                                        { 
                                            this.state.answersSpanish.map((answer, indice) =>
                                                <div className="form-check form-group" key={indice}>
                                                    <input className="form-check-input" 
                                                        type="radio"
                                                        disabled={true}
                                                        name="alternative-es"
                                                        id={`alt-es-${indice}`}
                                                        aux={indice}
                                                        value={indice}
                                                        checked={answer.correct}
                                                        onChange={this.changeCorrect}
                                                    />
                                                    <input className="form-control" type="text" value={answer.answer} name={indice} onChange={this.changeAlternativeSpanish} />
                                                    <span className="remove-alternative" onClick={() => this.removeAlternative(indice)}>
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </div>
                                            ) 
                                        }
                                    </div>
                                </div>
                                
                            </div>
                            <hr />
                            <div className="row row-center">
                                <div className="col-8">
                                <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                                </div>
                                <div className="col-md-4 text-right">
                                <button
                                    className="button-full btn-save"
                                    onClick={() => this.edit()}
                                    type="button"
                                >
                                    <i className="fa fa-check"></i> Editar
                                </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuizEdit;