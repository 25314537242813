import React, { Component } from 'react';

import './Ranking.scss';
import { ReactComponent as IfaIcon } from '../../../assets/images/logo/icone-ifa.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/estrelas.svg';

import ParticipantService from '../../../services/participant.service';

export default class Ranking extends Component {
  constructor() {
    super()
    this.state = {
      participants: []
    }
  }

  componentWillMount = async () => {
    const participants = await ParticipantService.ranking();
    await this.setState({
      participants: participants
    })
  }

  participantsPodium() {
    let participantsList = []
    let first, second, third

    this.state.participants.map((item, index) => {
      if (index === 1) {
        second = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="code">{item.class}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base second">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      } else if (index === 0) {
        first = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="code">{item.class}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base first">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      } else if (index === 2) {
        third = (
          <div className="ranking-podium-item">
            <div>
              {/* <span className="avatar"></span> */}
              <span className="name">{item.name.split(' ')[0]}</span>
              <span className="code">{item.class}</span>
              <span className="points">
                <IfaIcon
                  className="ifa-icon"
                />
                x{item.IFAs}
              </span>
            </div>
            <div key={index} className="ranking-podium-base third">
              <span className="placing">{index + 1}</span>
            </div>
          </div>
        )
      }
    })

    participantsList.push(second, first, third)
    return participantsList;
  }

  participantsRanking() {
    let participantsList = []

    participantsList = this.state.participants.map((item, index) => {
      if (index >= 3 && index < 20) {
        return (
          <li key={index} className="ranking-list-item">
            <span className="placing">{index + 1}</span>
            <span className="name">
              {/* <img src="" alt="" /> */}
              <span>
                {item.name}<br />
                {item.class}
              </span>
            </span>
            <span className="points">
              <IfaIcon
                className="ifa-icon"
              />
              x{item.IFAs}
            </span>
          </li>
        )
      }
    })
    return participantsList;
  }

  render() {
    return (
      <div className="box-card-fullscreen">
        <h3 className="form-title">Placar</h3>
        <main className="ranking-main">
          <div className="box-card ranking-legend">
            <h3>Legenda</h3>
            <span>
              <StarIcon className="star half" /> = <IfaIcon className="ifa" /> x5
            </span>
            <span>
              <StarIcon className="star full" /> = <IfaIcon className="ifa" /> x10
            </span>
            <span>
              Jogo = <IfaIcon className="ifa" /> x1
            </span>
          </div>

          <div className="ranking-podium mt-3">
            {this.participantsPodium()}
          </div>

          <div className="card ranking-content">
            <ul className="ranking-list">
              {this.participantsRanking()}
            </ul>

          </div>
        </main>
      </div>
    );
  }
}