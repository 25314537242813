import { FilterService } from "./filter.service";

const navigate = function ({
    link, 
    verifyToken = true,  
    goBack = false,
    reload = false
}) {

    new Promise((resolve, reject) => {
        if (!verifyToken) {
            resolve();
            return 
        }

        try {
            const filterService = new FilterService(this);
            filterService.runAllFilters();

            resolve();
        } catch(err) {
            reject(err)
        }
    })
    .then(() => {
        switch (true) {
            case reload:
                window.location.reload();
                break;
            case goBack:
                this.props.history.goBack();
                break;
            default:
                this.props.history.push(link);
                break;
        } 
    })
    .catch((err) => console.error(err));
};

const navigateUsingAchor = (e, component) => {
    e.preventDefault();

    const link = e.target.getAttribute('href');
    const navigator = navigate.bind(component);

    navigator({ link })
}

export { navigate, navigateUsingAchor };