import moment from "moment";
import "moment/locale/pt-br";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import DashboardService from "../../../services/dashboard.service";
import PowerService from "../../../services/power.service";
import MissionService from "../../../services/mission.service";
import ExcelService from "../../../services/excel.service";
import ReactExport from "react-data-export";
import { useState } from "react";
import InstructorService from "../../../services/instructor.service";
import WorkshopService from "../../../services/workshop.service";
import orderByWorkshop from "../../../shared/utils/orderByWorkshop";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const MySwal = withReactContent(Swal);

export default function ReportsModal({ filter }) {
  const [powers, setPowers] = useState([]);
  const [missions, setMissions] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [workshops, setWorkshops] = useState([]);

  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attributions, setAttributions] = useState([]);

  const [code, setCode] = useState("");

  function renderExportButtons() {
    if (loading || !classes.length) {
      return <></>;
    }

    return (
      <>
        <div className="col-md-12">{renderButtonReport()}</div>
        <div className="col-md-12">{renderButtonPresence()}</div>
      </>
    );
  }

  function renderButtonReport() {
    return (
      <ExcelFile
        element={
          <button 
            className="button-full btn-create modal-btn button-w-100"
          >
            <i className="fa fa-file-excel"></i> Relatório Geral
          </button>
        }
        filename={"Relatório Geral"}
      >
        <ExcelSheet
          dataSet={workshopsControlSheet()}
          name="Controle de oficinas"
        />
        <ExcelSheet
          dataSet={scheduledWorkshopsSheet()}
          name="Oficinas agendadas"
        />
        <ExcelSheet
          dataSet={workshopReportSheet()}
          name="Relatório de oficinas (app)"
        ></ExcelSheet>
        <ExcelSheet dataSet={classesSheet()} name="Turmas"></ExcelSheet>
        <ExcelSheet
          dataSet={completedWorkshopSheet()}
          name="Oficinas"
        ></ExcelSheet>
        <ExcelSheet dataSet={powersSheet()} name="Poderes"></ExcelSheet>
        <ExcelSheet dataSet={missionsSheet()} name="Missões"></ExcelSheet>
        <ExcelSheet dataSet={partnersSheet()} name="Parceiros" />
        <ExcelSheet
          dataSet={institutionsSheet()}
          name="Instituições"
        ></ExcelSheet>
        <ExcelSheet
          dataSet={instructorsSheet()}
          name="Cadastro instrutor (app)"
        ></ExcelSheet>
      </ExcelFile>
    );
  }

  function renderButtonPresence() {
    return (
      <ExcelFile
        element={
          <button className="button-full btn-create modal-btn button-w-100">
            <i className="fa fa-file-excel"></i> Oficinas realizadas
          </button>
        }
        filename={"Oficinas realizadas"}
      >
        <ExcelSheet
          dataSet={workshopsCompletedSheet()}
          name="Oficinas"
        ></ExcelSheet>
      </ExcelFile>
    );
  }

  function workshopsControlSheet() {
    let attributions = [];

    for (const cl of classes) {
      for (const wk of cl.workshops) {
        if (!existsData(wk?.presenceList)) {
          continue;
        }

        attributions.push({
          dateTime: wk?.dateTime,
          workshops: wk,
          participants: cl?.participants,
          infos: {
            code: cl?.code,
            type: cl?.type,
            instructor: cl?.instructor?.name,
            institution: cl?.instructor?.institution?.name,
          },
        });
      }
    }

    attributions.sort((w1, w2) => {
      return w1.dateTime > w2.dateTime ? 1 : -1;
    });

    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Data de lançamento", 40),
      getColumn("Instituição", 40),
      getColumn("Código da turma", 20),
      getColumn("Oficina", 20),
      getColumn("Data realização", 20),
      getColumn("Tipo", 20),
      getColumn("Instrutor", 20),
      getColumn("Quantidade de presentes", 20),
      getColumn("Poderes", 20),
      getColumn("Missões", 20),
      getColumn("Missões realizadas", 20),
      getColumn("Média idade", 20),
    ];

    let rows = [];

    if (!attributions.length) {
      return ExcelService.formatData(columns, rows);
    }

    const dates = {
      start: moment(attributions[0].dateTime).startOf("week"),
      lastData: moment(attributions[attributions.length - 1].dateTime)
        .endOf("week")
        .add(1, "days"),
    };

    let start = dates.start.clone(),
      end;

    do {
      end = moment.min(start.clone().subtract(6, "days"), dates.lastData);

      let filtered = attributions.filter(
        (att) =>
          moment(att.dateTime).isBetween(end, start) ||
          moment(att.dateTime).isSame(start, "day") ||
          moment(att.dateTime).isSame(end, "day")
      );

      if (filtered.length === 0) {
        start.add(7, "days");
        continue;
      }

      filtered.forEach((item) => {
        let index = attributions.findIndex(
          (element) => element.dateTime === item.dateTime
        );
        attributions.splice(index, 1);
      });

      filtered.sort((a, b) => {
        return a.dateTime > b.dateTime ? 1 : -1;
      });

      for (const data of filtered) {
        let ageAuxiliar = moment.min([moment(), moment(data.dateTime)]);
        let attendance = 0,
          qtdPowers = 0,
          qtdMissions = 0,
          qtdMissionsCompleted = 0,
          agesSum = 0;

        for (const participant of data.participants) {
          let age = moment
            .duration(ageAuxiliar.diff(participant.born))
            .get("years");
          if (age > 0 && age < 100) agesSum += age;
        }

        for (const participant of data.workshops.presenceList) {
          if (participant.presence) attendance++;
          if (participant.power) qtdPowers++;
          if (participant.mission) qtdMissions++;
          if (participant.star) qtdMissionsCompleted++;
        }

        rows.push(
          Array.of(
            start.format("DD/MM/YYYY"),
            data.infos.institution,
            data.infos.code,
            findWorkshopById(data.workshops.workshop),
            moment(data.dateTime).format("DD/MM/YYYY"),
            data.infos.type === "campaign"
              ? "Modo Campanha"
              : data.infos.type === "workshop"
              ? "Modo Oficina Única"
              : data.infos.type === "campaignLevel1"
              ? "Modo Campanha Aprendiz"
              : "Modo Campanha Especialista",
            data.infos.instructor,
            attendance,
            qtdPowers,
            qtdMissions,
            qtdMissionsCompleted,
            data.participants.length
              ? Math.round(agesSum / data.participants.length)
              : 0
          )
        );
      }

      if (filtered.length !== 0) {
        rows.push(Array.of());
      }

      start.add(7, "days");
    } while (end < dates.lastData);

    return ExcelService.formatData(columns, rows);
  }

  function scheduledWorkshopsSheet() {
    let attributions = [];

    for (const cl of classes) {
      for (const wk of cl.workshops) {
        if (existsData(wk.presenceList)) {
          continue;
        }

        attributions.push({
          dateTime: wk.dateTime,
          workshops: wk,
          participants: cl.participants,
          infos: {
            code: cl.code,
            type: cl.type,
            instructor: cl.instructor !== null ? cl.instructor.name : " - ",
            institution:
              cl.instructor !== null ? cl.instructor.institution.name : " - ",
          },
        });
      }
    }

    attributions.sort((w1, w2) => {
      return w1.dateTime > w2.dateTime ? 1 : -1;
    });

    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Data de lançamento", 40),
      getColumn("Instituição", 40),
      getColumn("Código da turma", 20),
      getColumn("Oficina", 20),
      getColumn("Data realização", 20),
      getColumn("Tipo", 20),
      getColumn("Instrutor", 20),
      getColumn("Quantidade de presentes", 20),
      getColumn("Poderes", 20),
      getColumn("Missões", 20),
      getColumn("Missões realizadas", 20),
      getColumn("Média idade", 20),
    ];

    let rows = [];

    if (!attributions.length) {
      return ExcelService.formatData(columns, rows);
    }

    const dates = {
      start: moment(attributions[0].dateTime).startOf("week"),
      lastData: moment(attributions[attributions.length - 1].dateTime)
        .endOf("week")
        .add(1, "days"),
    };

    let start = dates.start.clone(),
      end;

    do {
      end = moment.min(start.clone().subtract(6, "days"), dates.lastData);

      let filtered = attributions.filter(
        (att) =>
          moment(att.dateTime).isBetween(end, start) ||
          moment(att.dateTime).isSame(start, "day") ||
          moment(att.dateTime).isSame(end, "day")
      );

      if (filtered.length === 0) {
        start.add(7, "days");
        continue;
      }

      filtered.forEach((item) => {
        let index = attributions.findIndex(
          (element) => element.dateTime === item.dateTime
        );
        attributions.splice(index, 1);
      });

      filtered.sort((a, b) => {
        return a.dateTime > b.dateTime ? 1 : -1;
      });

      for (const data of filtered) {
        let ageAuxiliar = moment.min([moment(), moment(data.dateTime)]);
        let attendance = 0,
          qtdPowers = 0,
          qtdMissions = 0,
          qtdMissionsCompleted = 0,
          agesSum = 0;

        for (const participant of data.participants) {
          let age = moment
            .duration(ageAuxiliar.diff(participant.born))
            .get("years");
          if (age > 0 && age < 100) agesSum += age;
        }

        for (const participant of data.workshops.presenceList) {
          if (participant.presence) attendance++;
          if (participant.power) qtdPowers++;
          if (participant.mission) qtdMissions++;
          if (participant.star) qtdMissionsCompleted++;
        }

        rows.push(
          Array.of(
            start.format("DD/MM/YYYY"),
            data.infos.institution,
            data.infos.code,
            findWorkshopById(data.workshops.workshop),
            moment(data.dateTime).format("DD/MM/YYYY"),
            data.infos.type === "campaign"
              ? "Modo Campanha"
              : data.infos.type === "workshop"
              ? "Modo Oficina Única"
              : data.infos.type === "campaignLevel1"
              ? "Modo Campanha Aprendiz"
              : "Modo Campanha Especialista",
            data.infos.instructor,
            attendance,
            qtdPowers,
            qtdMissions,
            qtdMissionsCompleted,
            data.participants.length
              ? Math.round(agesSum / data.participants.length)
              : 0
          )
        );
      }

      if (filtered.length !== 0) {
        rows.push(Array.of());
      }

      start.add(7, "days");
    } while (end < dates.lastData);

    return ExcelService.formatData(columns, rows);
  }

  function workshopReportSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Código", 10),
      getColumn("Nome", 20),
      getColumn("Tipo", 10),
      getColumn("Participantes", 12),
      getColumn("Oficina", 20),
      getColumn("Localização", 20),
      getColumn("Instrutor", 25),
      getColumn("Instituição", 25),
      getColumn("País", 10),
      getColumn("Estado", 10),
      getColumn("Oficina concluída", 16),
      getColumn("Presença Completa", 16),
      getColumn("Poderes Completos", 16),
      getColumn("Missões Completas", 16),
      getColumn("Estrelas Completas", 16),
      getColumn("Participante", 20),
      getColumn("Data de Nascimento", 16),
      getColumn("Idade", 7),
      getColumn("Gênero", 13),
      getColumn("Presente", 10),
      getColumn("Poder", 20),
      getColumn("Missão", 20),
      getColumn("Missão concluída", 14),
      getColumn("Data cadastro turma", 17),
      getColumn("Data realização oficina", 19),
      getColumn("Data do último registro", 19),
      getColumn("Última atribuição", 19),
    ];

    let data = [];

    const sortedClasses = classes.sort((a, b) => {
      return a.code > b.code ? 1 : -1;
    });

    for (const classInfo of sortedClasses) {
      for (const workshop of classInfo.workshops) {
        const ageAuxiliar = moment.min([moment(), moment(workshop.dateTime)]);

        for (const presence of workshop.presenceList) {
          // console.log(workshop);
          data.push([
            classInfo.code,
            classInfo.name !== null ? classInfo.name : " - ",
            classInfo.type === "campaign"
              ? "Modo Campanha"
              : classInfo.type === "workshop"
              ? "Modo Oficina Única"
              : classInfo.type === "campaignLevel1"
              ? "Modo Campanha Aprendiz"
              : "Modo Campanha Especialista",
            existsData(workshop.presenceList)
              ? workshop.presenceList.length
              : 0,
            findWorkshopById(workshop.workshop),
            workshop.localization,
            classInfo.instructor !== null ? classInfo.instructor.name : " - ",
            classInfo.instructor !== null
              ? classInfo.instructor.institution.name
              : " - ",
            classInfo.instructor !== null
              ? classInfo.instructor.institution.address.country
              : " - ",
            classInfo.instructor !== null
              ? classInfo.instructor.institution.address.state
              : " - ",
            workshop.completed ? "Sim" : "Não",
            workshop.presenceCompleted ? "Sim" : "Não",
            workshop.powersCompleted ? "Sim" : "Não",
            workshop.missionsCompleted ? "Sim" : "Não",
            workshop.starsCompleted ? "Sim" : "Não",
            presence.name ? presence.name : " - ",
            presence.born ? moment(presence.born).format("DD/MM/YYYY") : " - ",
            presence.born
              ? moment.duration(ageAuxiliar.diff(presence.born)).get("years")
              : " - ",
            presence.gender ? formatGender(presence.gender) : " - ",
            presence.presence ? "Sim" : "Não",
            presence.power ? presence.power : " - ",
            presence.mission ? presence.mission : " - ",
            presence.star ? "Sim" : "Não",
            moment(classInfo.createdAt).format("DD/MM/YYYY"),
            moment(workshop.dateTime).format("DD/MM/YYYY"),
            workshop.attributionLog?.length
              ? moment(
                  workshop.attributionLog[workshop.attributionLog.length - 1]
                    .date
                ).format("DD/MM/YYYY")
              : "-",
            workshop.attributionLog?.length
              ? workshop.attributionLog[workshop.attributionLog.length - 1].type
              : "-",
          ]);
        }
      }
    }

    data.sort(function (a, b) {
      if (a[0] == b[0]) {
        return b[15].localeCompare(a[15]);
      }
      return 0;
    });

    data.sort((a, b) => {
      if (a[0] === b[0]) return orderByWorkshop(a[4], b[4]);

      return 0;
    });

    return ExcelService.formatData(columns, data);
  }

  function classesSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Cidade", 20),
      getColumn("Estado", 10),
      getColumn("Instituição", 25),
      getColumn("Instrutor", 25),
      getColumn("Data de cadastro", 25),
      getColumn("Código da turma", 20),
      getColumn("Participante", 20),
      getColumn("Oficinas", 20),
      getColumn("Estrelas", 20),
      getColumn("Gênero", 20),
    ];

    let data = [];

    const sortedClasses = classes.sort((a, b) => {
      return a.code > b.code ? 1 : -1;
    });

    for (const classInfo of sortedClasses) {
      for (const wk of classInfo.workshops) {
        for (const pl of wk.presenceList) {
          let index = data.findIndex((item) => item[0] === pl._id);

          if (index < 0) {
            data.push([
              pl._id,
              classInfo.instructor?.institution?.address?.city,
              classInfo.instructor?.institution?.address?.state,
              classInfo.instructor?.institution?.name,
              classInfo.instructor?.name,
              moment(classInfo.createdAt).format("DD/MM/YYYY"),
              classInfo.code,
              pl.name ? pl.name : " - ",
              pl.presence ? 1 : 0,
              pl.star ? 1 : 0,
              pl.gender ? formatGender(pl.gender) : " - ",
            ]);
          } else {
            data[index][8] = pl.presence ? data[index][8] + 1 : data[index][8];
            data[index][9] = pl.star ? data[index][9] + 1 : data[index][9];
          }
        }
      }
    }

    data = data.map((item) => {
      item.splice(0, 1);
      return item;
    });

    return ExcelService.formatData(columns, data);
  }

  function completedWorkshopSheet() {
    let workshopsArr = [],
      total = 0;

    for (const cl of classes) {
      for (const wk of cl.workshops) {
        if (!existsData(wk.presenceList)) {
          continue;
        }

        let workshop = findWorkshopById(wk.workshop);

        let index = workshopsArr.findIndex((wk) => wk[0] === workshop);

        if (index < 0) {
          workshopsArr.push(Array.of(workshop, 1));
        } else {
          workshopsArr[index][1] = workshopsArr[index][1] + 1;
        }

        total++;
      }
    }

    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [getColumn("Oficinas aplicadas", 20), getColumn("", 10)];

    workshopsArr.sort((wk1, wk2) => {
      return wk1[0] > wk2[0] ? 1 : -1;
    });

    workshopsArr.push(Array.of("Total", total));

    let rows = workshopsArr;

    return ExcelService.formatData(columns, rows);
  }

  function powersSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    powers.sort((p1, p2) => {
      return p1.pt.name > p2.pt.name ? 1 : -1;
    });

    const columns = [
      getColumn("Período", 20),
      ...powers.map((power) => getColumn(power.pt.name, 15)),
      getColumn("TOTAL", 10),
    ];

    let rows = [];

    if (!attributions.length) {
      return ExcelService.formatData(columns, rows);
    }

    const dates = {
      start: moment(attributions[0].dateTime).startOf("week").add(1, "days"),
      end: moment(attributions[attributions.length - 1].dateTime)
        .endOf("week")
        .add(1, "days"),
    };

    let total = powers.map((p) => 0);
    let start = dates.start.clone(),
      end;

    do {
      end = moment.min(start.clone().add(6, "days"), dates.end);

      let filtered = attributions.filter(
        (att) =>
          moment(att.dateTime).format("YYYY-MM-DD") >=
            start.format("YYYY-MM-DD") &&
          moment(att.dateTime).format("YYYY-MM-DD") <= end.format("YYYY-MM-DD")
      );

      if (filtered.length === 0) {
        start.add(7, "days");
        continue;
      }

      let row = [],
        sum = 0;
      row.push(start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY"));

      filtered.forEach((element) => {
        powers
          .map((p) => p._id)
          .forEach((power, index) => {
            let count = element.powers.filter((p) => p.id === power).length;
            if (!count) {
              count = 0;
            }
            sum += count;
            total[index] += count;

            if (row.length === 1) {
              row.push(count);
            } else {
              let num = row[index + 1] || 0;
              row[index + 1] = num + count;
            }
          });
      });

      row.push(sum);
      if (sum > 0) {
        rows.push(row);
      }

      start.add(7, "days");
    } while (end < dates.end);

    rows.push(["TOTAL", ...total, total.reduce((x, y) => x + y, 0)]);

    return ExcelService.formatData(columns, rows);
  }

  function missionsSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    let missionsAndPower = missions.map((mission) => {
      let power = powers.filter((power) => mission.power === power._id);
      if (power[0]) {
        return {
          power: power[0].pt.name,
          mission: {
            name: mission.pt.name,
            id: mission._id,
          },
        };
      }

      return {
        power: "",
        mission: {
          name: mission.pt.name,
          id: mission._id,
        },
      };
    });

    missionsAndPower.sort((m1, m2) => {
      return m1.power > m2.power ? 1 : -1;
    });

    const columns = [
      getColumn("Período", 20),
      ...missionsAndPower.map((item) => getColumn(item.mission.name, 15)),
      getColumn("TOTAL", 10),
    ];

    let rows = [];

    const powerHeader = generateHeaderPower(missionsAndPower);

    if (!attributions.length) {
      return Array.of(
        powerHeader[0],
        ExcelService.formatData(columns, rows)[0]
      );
    }

    const dates = {
      start: moment(attributions[0].dateTime).startOf("week").add(1, "days"),
      end: moment(attributions[attributions.length - 1].dateTime)
        .endOf("week")
        .add(1, "days"),
    };

    let total = missionsAndPower.map((m) => 0);
    let start = dates.start.clone(),
      end;

    do {
      end = moment.min(start.clone().add(6, "days"), dates.end);

      let filtered = attributions.filter(
        (att) =>
          moment(att.dateTime).format("YYYY-MM-DD") >=
            start.format("YYYY-MM-DD") &&
          moment(att.dateTime).format("YYYY-MM-DD") <= end.format("YYYY-MM-DD")
      );

      if (filtered.length === 0) {
        start.add(7, "days");
        continue;
      }

      let row = [],
        sum = 0;
      row.push(start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY"));

      filtered.forEach((element) => {
        missionsAndPower.forEach((item, index) => {
          let count = element.missions.filter(
            (m) => m.id === item.mission.id
          ).length;
          if (!count) {
            count = 0;
          }
          sum += count;
          total[index] += count;

          if (row.length === 1) {
            row.push(count);
          } else {
            let num = row[index + 1] || 0;
            row[index + 1] = num + count;
          }
        });
      });

      row.push(sum);
      if (sum > 0) {
        rows.push(row);
      }

      start.add(7, "days");
    } while (end < dates.end);

    rows.push(["TOTAL", ...total, total.reduce((x, y) => x + y, 0)]);

    return Array.of(powerHeader[0], ExcelService.formatData(columns, rows)[0]);
  }

  function partnersSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Código", 10),
      getColumn("Nome", 30),
      getColumn("Tipo", 10),
      getColumn("Instrutor", 35),
      getColumn("Instituição", 30),
      getColumn("Participantes", 15),
      getColumn("Média de idade", 15),
      getColumn("Oficinas concluídas", 15),
      getColumn("Atendimentos", 16),
      getColumn("Poderes atribuídos", 20),
      getColumn("Missões atribuídas", 20),
      getColumn("Data de cadastro", 18),
      getColumn("Data Oficina 1", 15),
      getColumn("Data Oficina 2", 15),
      getColumn("Data Oficina 3", 15),
      getColumn("Data Oficina 4", 15),
      getColumn("Data Oficina 5", 15),
    ];

    let data = classes.map((item) => {
      const completedWorkshops = item.workshops.filter((wk) =>
        existsData(wk.presenceList)
      );

      let attendance = 0;
      let qtdPowers = 0;
      let qtdMissions = 0;
      let agesSum = 0;

      const ageAuxiliar = moment.min([
        moment(),
        moment(item.workshops[0].dateTime),
      ]);

      item.participants.forEach((participant) => {
        const age = moment
          .duration(ageAuxiliar.diff(participant.born))
          .get("years");
        if (age > 0 && age < 100) agesSum += age;
      });

      for (const wk of item.workshops) {
        for (const pr of wk.presenceList) {
          if (pr.presence) attendance++;
          if (pr.power) qtdPowers++;
          if (pr.mission) qtdMissions++;
        }
      }

      return [
        item.code ? item.code : " - ",
        item.name ? item.name : " - ",
        item.type === "campaign"
          ? "Modo Campanha"
          : item.type === "workshop"
          ? "Modo Oficina Única"
          : item.type === "campaignLevel1"
          ? "Modo Campanha Aprendiz"
          : "Modo Campanha Especialista",
        item.instructor ? item.instructor?.name : " - ",
        item.instructor ? item.instructor?.institution?.name : " - ",
        existsData(item.participants) ? item.participants.length : 0,
        existsData(item.participants)
          ? Math.round(agesSum / item.participants.length)
          : 0,
        completedWorkshops.length,
        attendance,
        qtdPowers,
        qtdMissions,
        item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - ",
        completedWorkshops.length >= 1
          ? moment(item.workshops[0].dateTime).format("DD/MM/YYYY")
          : " - ",
        completedWorkshops.length >= 2
          ? moment(item.workshops[1].dateTime).format("DD/MM/YYYY")
          : " - ",
        completedWorkshops.length >= 3
          ? moment(item.workshops[2].dateTime).format("DD/MM/YYYY")
          : " - ",
        completedWorkshops.length >= 4
          ? moment(item.workshops[3].dateTime).format("DD/MM/YYYY")
          : " - ",
        completedWorkshops.length >= 5
          ? moment(item.workshops[4].dateTime).format("DD/MM/YYYY")
          : " - ",
      ];
    });

    return ExcelService.formatData(columns, data);
  }

  function institutionsSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Nome", 40),
      getColumn("Tipo de instituição", 40),
      getColumn("Site", 40),
      getColumn("Telefone", 20),
      getColumn("País", 20),
      getColumn("Estado", 20),
      getColumn("Cidade", 20),
      getColumn("Modo Oficina Única", 20),
      getColumn("Modo Campanha", 20),
      getColumn("Modo Campanha Aprendiz", 20),
      getColumn("Modo Campanha Especialista", 20),
      getColumn("Total de oficinas do período selecionado", 20),
      getColumn("Oficinas concluídas", 20),
      getColumn("Oficinas incompletas", 20),
      getColumn("Participantes", 20),
      getColumn("Atendimentos", 20),
      getColumn("Data cadastro instituição", 40),
    ];

    let rows = [];

    classes.sort((i1, i2) => {
      if (i1.instructor && i2.instructor)
        return i1.instructor.createdAt > i2.instructor.createdAt ? 1 : -1;
    });

    let institutionsAux = [];
    classes.forEach((i) => {
      let index;
      if (i.instructor) {
        index = institutionsAux.findIndex(
          (iAux) =>
            iAux.name.toUpperCase() ===
            i.instructor?.institution?.name.toUpperCase()
        );
      }
      if (index > -1) {
        //ja existe uma instituição com mesmo nome
        let attendments = 0;
        i.workshops.forEach((wk) => {
          wk.presenceList.forEach((presence) => {
            if (presence.presence) attendments++;
          });
        });

        attendments += institutionsAux[index].attendments;
        let totalWorks = i.workshops.length + institutionsAux[index].totalWorks;
        let totalWorksCompleted =
          i.workshops.filter((w) => existsData(w.presenceList)).length +
          institutionsAux[index].totalWorksCompleted;
        let totalParticipants = existsData(i.participants)
          ? i.participants.length + institutionsAux[index].totalParticipants
          : institutionsAux[index].totalParticipants;

        institutionsAux[index] = {
          works:
            i.type === "workshop"
              ? institutionsAux[index].works++
              : institutionsAux[index].works,
          campaigns:
            i.type === "campaign"
              ? institutionsAux[index].campaigns++
              : institutionsAux[index].campaigns,
          campaignLevel1:
            i.type === "campaignLevel1"
              ? institutionsAux[index].campaignLevel1++
              : institutionsAux[index].campaignLevel1,
          campaignLevel2:
            i.type === "campaignLevel2"
              ? institutionsAux[index].campaignLevel2++
              : institutionsAux[index].campaignLevel2,
          ...institutionsAux[index],
          totalWorks: totalWorks,
          totalWorksCompleted: totalWorksCompleted,
          totalParticipants: totalParticipants,
          attendments: attendments,
        };
      } else {
        //nao é igual
        let attendments = 0;
        i.workshops.forEach((wk) => {
          wk.presenceList.forEach((presence) => {
            if (presence.presence) attendments++;
          });
        });

        institutionsAux.push({
          name: i.instructor !== null ? i.instructor.institution.name : " - ",
          type: i?.instructor?.institution?.type || " - ",
          website:
            i.instructor !== null ? i.instructor.institution.website : " - ",
          phone: i.instructor !== null ? i.instructor.institution.phone : " - ",
          country:
            i.instructor !== null
              ? i.instructor.institution.address.country
              : " - ",
          state:
            i.instructor !== null
              ? i.instructor.institution.address.state
              : " - ",
          city:
            i.instructor !== null
              ? i.instructor.institution.address.city
              : " - ",
          createdAt: i.instructor !== null ? i.instructor.createdAt : " - ",
          works: i.type === "workshop" ? 1 : 0,
          campaigns: i.type === "campaign" ? 1 : 0,
          campaignLevel1: i.type === "campaignLevel1" ? 1 : 0,
          campaignLevel2: i.type === "campaignLevel2" ? 1 : 0,
          totalWorks: i.workshops.length,
          totalWorksCompleted: i.workshops.filter((w) =>
            existsData(w.presenceList)
          ).length,
          totalParticipants: existsData(i.participants)
            ? i.participants.length
            : 0,
          attendments: attendments,
        });
      }
    });

    let filtered = institutionsAux;

    rows = filtered.map((item) => {
      return [
        item.name ? item.name : " - ",
        item.type ?? " - ",
        item.website ? item.website : " - ",
        item.phone ? item.phone : " - ",
        item.country ? item.country : " - ",
        item.state ? item.state : " - ",
        item.city ? item.city : " - ",
        item.works,
        item.campaigns,
        item.campaignLevel1,
        item.campaignLevel2,
        item.totalWorks,
        item.totalWorksCompleted,
        item.totalWorks - item.totalWorksCompleted,
        item.totalParticipants,
        item.attendments,
        item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - ",
      ];
    });

    return ExcelService.formatData(columns, rows);
  }

  function instructorsSheet() {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("Nome", 40),
      getColumn("Email", 40),
      getColumn("Data de Nascimento", 20),
      getColumn("CPF", 20),
      getColumn("Nacionalidade", 20),
      getColumn("Telefone", 20),
      getColumn("Celular", 20),
      getColumn("Rua", 20),
      getColumn("Número", 20),
      getColumn("Complemento", 20),
      getColumn("Bairro", 20),
      getColumn("CEP", 20),
      getColumn("Cidade", 20),
      getColumn("Estado", 20),
      getColumn("País", 10),
      getColumn("Cursos", 30),
      getColumn("Instituição", 40),
      getColumn("Tipo de instituição", 40),
      getColumn("Site", 40),
      getColumn("Telefone", 20),
      getColumn("Rua", 20),
      getColumn("Número", 20),
      getColumn("Complemento", 20),
      getColumn("Bairro", 20),
      getColumn("CEP", 20),
      getColumn("Cidade", 20),
      getColumn("Estado", 20),
      getColumn("País", 10),
      getColumn("Professor", 10),
      getColumn("Treinamento", 10),
      getColumn("Status", 20),
      getColumn("Data cadastro instrutor", 40),
    ];

    let rows = [];

    instructors.sort((i1, i2) => {
      return i1.createdAt > i2.createdAt ? 1 : -1;
    });

    rows = instructors.map((item) => {
      return [
        item.name ? item.name : " - ",
        item.email ? item.email : " - ",
        item.born ? moment.utc(item.born).format("DD/MM/YYYY") : " - ",
        item.cpf ? item.cpf : " - ",
        item.nationality ? item.nationality : "-",
        item.phone ? item.phone : " - ",
        item.cellphone ? item.cellphone : " - ",

        item.address ? item.address.street : " - ",
        item.address ? item.address.number : " - ",
        item.address ? item.address.complement : " - ",
        item.address ? item.address.neighborhood : " - ",
        item.address ? item.address.zipcode : " - ",
        item.address ? item.address.city : " - ",
        item.address ? item.address.state : " - ",
        item.address ? item.address.country : " - ",
        item.courses ? item.courses : " - ",

        item.institution.name ? item.institution.name : " - ",
        item.institution.type ?? " - ",
        item.institution.website ? item.institution.website : "-",
        item.institution.phone ? item.institution.phone : "-",

        item.institution.address.street
          ? item.institution.address.street
          : " - ",
        item.institution.address.number
          ? item.institution.address.number
          : " - ",
        item.institution.address.complement
          ? item.institution.address.complement
          : " - ",
        item.institution.address.neighborhood
          ? item.institution.address.neighborhood
          : " - ",
        item.institution.address.zipcode
          ? item.institution.address.zipcode
          : " - ",
        item.institution.address.city ? item.institution.address.city : " - ",
        item.institution.address.state ? item.institution.address.state : " - ",
        item.institution.address.country
          ? item.institution.address.country
          : " - ",

        item.isTeacher ? "Sim" : "Não",
        item.trainingCompletedAt ? "Sim" : "Não",
        item.active ? "Ativo" : "Inativo",
        item.createdAt ? moment(item.createdAt).format("DD/MM/YYYY") : " - ",
      ];
    });

    return ExcelService.formatData(columns, rows);
  }

  function workshopsCompletedSheet() {
    const workshops = [];

    for (const cl of classes) {
      for (const wk of cl.workshops) {
        if (!existsData(wk.presenceList)) {
          continue;
        }

        let participants = [];
        for (const p of wk.presenceList) {
          if (!p.presence) {
            continue;
          }

          let age = moment
            .duration(moment(wk.dateTime).diff(p.born))
            .get("years");

          if (age >= 10 && age <= 18) {
            participants.push({
              name: p.name,
              born: p.born,
              age,
              gender: p.gender ? p.gender : " - ",
            });
          }
        }

        if (!participants.length) {
          continue;
        }

        workshops.push({
          dateTime: wk.dateTime,
          type: cl.type,
          institution: cl.instructor?.institution?.name,
          name: findWorkshopById(wk.workshop),
          instructor: cl.instructor?.name,
          participants,
        });
      }
    }

    const borderStyle = {
      style: "medium",
      color: "#000",
    };

    const border = {
      top: borderStyle,
      bottom: borderStyle,
      left: borderStyle,
      right: borderStyle,
    };

    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
        style: {
          font: { name: "Calibri", sz: 8, bold: true },
          alignment: { vertical: "center", horizontal: "center" },
          border,
        },
      };
    };

    const getTableCell = (value) => {
      return {
        value,
        style: {
          font: {
            name: "Calibri",
            sz: 8,
            bold: false,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border,
        },
      };
    };

    const getCell = (value) => {
      return {
        value,
        style: {
          font: { name: "Calibri", sz: 8, bold: true },
          alignment: { vertical: "center" },
        },
      };
    };

    workshops.sort((w1, w2) => {
      return w1.dateTime > w2.dateTime ? 1 : -1;
    });

    const getHeader = (workshop) => {
      const columns = [getColumn("", 2), getColumn("", 30)];
      delete columns[0].style.border;
      delete columns[1].style.border;
      return {
        xSteps: 1,
        columns,
        data: [
          [getCell(moment(workshop.dateTime).format("DD/MM/YYYY"))],
          [
            getCell(
              workshop.type === "campaign"
                ? "Modo Campanha"
                : workshop.type === "workshop"
                ? "Modo Oficina Única"
                : workshop.type === "campaignLevel1"
                ? "Modo Campanha Aprendiz"
                : "Modo Campanha Especialista"
            ),
          ],
          [getCell("Instituição: " + workshop.institution)],
          [getCell("Oficina: " + workshop.name)],
          [getCell("Instrutor: " + workshop.instructor)],
        ],
      };
    };

    const getTable = (workshop) => {
      return {
        columns: [
          getColumn("", 2),
          getColumn("NOME", 30),
          getColumn("DATA DE NASCIMENTO", 15),
          getColumn("IDADE", 6),
          getColumn("GÊNERO", 8),
        ],
        data: workshop.participants.map((p, index) => {
          return [
            index + 1,
            p.name,
            p.born,
            p.age,
            formatGender(p.gender).toUpperCase(),
          ].map(getTableCell);
        }),
      };
    };
    return workshops.flatMap((wk) => [getHeader(wk), getTable(wk)]);
  }

  // Utils

  function existsData(list) {
    return list.some((item) => item.name);
  }

  function generateHeaderPower(obj) {
    const getColumn = (title, width) => {
      return {
        title,
        width: { wch: width },
      };
    };

    const columns = [
      getColumn("", 20),
      ...obj.map((item) => getColumn(item.power, 15)),
      getColumn("", 10),
    ];

    const rows = [];

    return ExcelService.formatData(columns, rows);
  }

  function findWorkshopById(id) {
    return workshops.find((wk) => wk._id === id).pt.name;
  }

  function formatGender(gender) {
    const genders = ["male", "female", "other"];
    const display = ["Masculino", "Feminino", "Outro"];

    const index = genders.indexOf(gender);
    return index === -1 ? "-" : display[index];
  }

  function searchError(error) {
    setLoading(false);
    return showAlert("OK", "error", "Erro inesperado!");
  }

  async function search() {
    setLoading(true);
    if (code.length && code.length != 6)
      return showAlert("OK", "warning", "Código de turma inválido!");

    let action = code.length
      ? DashboardService.getReport({ code })
      : DashboardService.getReport({ ...filter });

    let instructors = await InstructorService.search({
      creationDateStart: filter.start,
      creationDateEnd:
        filter.end.length === 0 ? moment().format("YYYY-MM-DD") : filter.end,
    });

    let attributions = code.length
      ? await DashboardService.getAttributions({ code })
      : await DashboardService.getAttributions({ ...filter });

    setPowers(await PowerService.getAll());
    setMissions(await MissionService.getAll());
    setWorkshops(await WorkshopService.getAll());
    setInstructors(instructors.result);
    setAttributions(attributions);

    action
      .then((res) => {
        if (!res.result.length) {
          setLoading(false);
          return showAlert(
            "OK",
            "warning",
            "Não existe dados para serem exibidos!"
          );
        }

        setLoading(false);
        setClasses(res.result);
      })
      .catch(searchError);
  }

  function showAlert(buttonText, icon, title) {
    MySwal.fire({
      confirmButtonColor: "#87BF40",
      confirmButtonText: <span>{buttonText}</span>,
      icon: icon,
      title: <p>{title}</p>,
    });
  }

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="list-title">Relatórios</h3>
            <button
              className="power-button remove closed"
              data-dismiss="modal"
              aria-label="Close"
              title="Remover"
            >
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Código da turma: </label>
                  <input
                    type="text"
                    name="class"
                    className="form-control"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="col-md-12">
              <button
                className="button-full btn-create modal-btn button-w-100"
                disabled={loading}
                onClick={async () => await search()}
              >
                {loading ? "Carregando..." : "Gerar relatórios"}
              </button>
            </div>
            {renderExportButtons()}
          </div>
        </div>
      </div>
    </div>
  );
}
