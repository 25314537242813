import React, { Component } from 'react';
import './Power-edit.scss';
import * as moment from 'moment';

import PowerService from '../../../services/power.service';
import AwsService from '../../../services/aws.service';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class PowerEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      namePortuguese: '',
      descriptionPortuguese: '',
      nameEnglish: '',
      descriptionEnglish: '',
      nameSpanish: '',
      descriptionSpanish: '',
      capaPortuguese: '',
      capaAuxPortuguese: '',
      capaEnglish: '',
      capaAuxEnglish: '',
      capaSpanish: '',
      capaAuxSpanish: '',
      AwsService: new AwsService(),
      downloadUrl: ''
    }
  }

  componentWillMount = async () => {
    let { id } = this.props.match.params;
    let power = await PowerService.getById(id);

    await this.setState({
      id: power._id,
      namePortuguese: power.pt.name,
      nameEnglish: power.en.name,
      descriptionPortuguese: power.pt.description,
      descriptionEnglish: power.en.description,
      nameSpanish: power.es.name,
      descriptionSpanish: power.es.description
    })

    await this.getS3Files();
  }

  getS3Files = async() => {
    await this.setState({
      capaPortuguese: '',
      capaEnglish: '',
      capaSpanish: '',
    })

    let files = await this.state.AwsService.getFiles(this.state.id);
    files = files.filter(file => file.Key.split('/')[1] != '');

    files.forEach(async file => {
      if (file.Key.includes('capa_pt'))
          await this.setState({ capaPortuguese: [file] })
      else if (file.Key.includes('capa_en'))
          await this.setState({ capaEnglish: [file] })
      else if (file.Key.includes('capa_es'))
          await this.setState({ capaSpanish: [file] })
    })
  }

  download = async(name) => {
    await this.setState({
        downloadUrl: this.state.AwsService.download(name)
    })
  }

  deleteFile = async(name) => {
    MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'CONCLUIR',
        customClass: {
          confirmButton: 'confirm-button-class',
          cancelButton: 'cancel-button-class'
        },
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: <span style={{ color: '#E81E47' }}>Excluir imagem?</span>,
        html: <p style={{ color: '#000' }}>
          Ao excluir uma imagem, ela não aparecerá mais para os usuários, será necessário carregar outra imagem.<br /><br />
          <span style={{ color: '#E81E47' }}>Essa ação é irreversível!</span>
        </p>,
      }).then(async (result) => {
        if (result.value){
            console.log(name)
            await this.state.AwsService.deleteFile(name);
            await this.getS3Files();
        }
    })
  }

  handleInputChange = async (e) => {
      const target = e.target;

      await this.setState({
          [target.name]: target.value
      });
  }

  edit = async() => {
    console.log(this.state)
    if (
        this.state.namePortuguese == "" ||
        this.state.descriptionPortuguese == "" ||
        this.state.nameEnglish == "" ||
        this.state.descriptionEnglish == "" ||
        this.state.capaPortuguese == "" ||
        this.state.capaEnglish == "" ||
        this.state.nameSpanish == "" ||
        this.state.descriptionSpanish == "" ||
        this.state.capaSpanish == ""
      ) {
        MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>OK</span>,
            icon: 'error',
            title: <p>Há campos obrigatórios que não foram preenchidos. Lembre-se: ao selecionar uma imagem, deve-se clicar no botão "Enviar".</p>
        })
    } else {

        const power = {
          pt: {
            name: this.state.namePortuguese,
            description: this.state.descriptionPortuguese
          },
          en: {
            name: this.state.nameEnglish,
            description: this.state.descriptionEnglish
          },
          es: {
            name: this.state.nameSpanish,
            description: this.state.descriptionSpanish
          }
        }

        try{
            let res = await PowerService.edit(this.state.id, power);
            if(res._id){
                MySwal.fire({
                    confirmButtonColor: '#87BF40',
                    confirmButtonText: <span>OK</span>,
                    icon: 'success',
                    title: <p>Poder atualizado com sucesso</p>
                })
                this.props.history.push('/powers')
            }
        } catch(err){
            console.log(err)
            MySwal.fire({
                confirmButtonColor: '#87BF40',
                confirmButtonText: <span>Tentar novamente</span>,
                icon: 'error',
                title: <p>Erro ao atualizar poder.</p>
            })
        }
    }
  }

  selectFile = async(e) => {
    const input = e.target;

    if(input.id == 'capaAuxPortuguese')
        await this.setState({ capaAuxPortuguese: input.files })
    else if(input.id == 'capaAuxEnglish')
        await this.setState({ capaAuxEnglish: input.files })
    else if(input.id == 'capaAuxSpanish')
        await this.setState({ capaAuxSpanish: input.files })
  }

  upload = async(name) => {
    try{
      if (name == 'capaPortuguese' && this.state.capaAuxPortuguese.length > 0) {
        await this.state.AwsService.uploadfile(this.state.capaAuxPortuguese[0],
            `capa_pt.${this.state.capaAuxPortuguese[0].name.split('.')[this.state.capaAuxPortuguese[0].name.split('.').length - 1]}`,
            this.state.id);
      } else if (name == 'capaEnglish' && this.state.capaAuxEnglish.length > 0) {
        await this.state.AwsService.uploadfile(this.state.capaAuxEnglish[0],
            `capa_en.${this.state.capaAuxEnglish[0].name.split('.')[this.state.capaAuxEnglish[0].name.split('.').length - 1]}`,
            this.state.id);
      } else if (name == 'capaSpanish' && this.state.capaAuxSpanish.length > 0) {
        await this.state.AwsService.uploadfile(this.state.capaAuxSpanish[0],
            `capa_es.${this.state.capaAuxSpanish[0].name.split('.')[this.state.capaAuxSpanish[0].name.split('.').length - 1]}`,
            this.state.id);
      }
      await this.getS3Files();
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <div className="content-card">
          <div className="centered-title">
              <h1>Edição de poder</h1>
          </div>

          <div className="row">
              <div className="col-md-12">
                  <form>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Nome em português:<span className="highlighter">*</span></label>
                                  <input type="text" name="namePortuguese" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.namePortuguese} />
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Nome em inglês:<span className="highlighter">*</span></label>
                                  <input type="text" name="nameEnglish" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.nameEnglish} />
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Nome em espanhol:<span className="highlighter">*</span></label>
                                  <input type="text" name="nameSpanish" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.nameSpanish} />
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Descrição em português:<span className="highlighter">*</span></label>
                                  <textarea name="descriptionPortuguese" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.descriptionPortuguese}></textarea>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Descrição em inglês:<span className="highlighter">*</span></label>
                                  <textarea name="descriptionEnglish" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.descriptionEnglish}></textarea>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Descrição em espanhol:<span className="highlighter">*</span></label>
                                  <textarea name="descriptionSpanish" className="form-control" required
                                      onChange={this.handleInputChange} value={this.state.descriptionSpanish}></textarea>
                              </div>
                          </div>

                          <div className="col-md-12 mb-2">
                              <h5>
                                  Capa em português<span className="highlighter">*</span>
                              </h5>
                          </div>
                          {
                              this.state.capaPortuguese.length > 0 || this.state.capaPortuguese != '' ?
                              <div className="col-md-12 mb-4">
                                  <table className="table table-hover table-striped table-responsive">
                                      <thead>
                                          <tr>
                                              <th>Nome</th>
                                              <th>Data de modificação</th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody >
                                          {
                                              this.state.capaPortuguese.map(file => {
                                                  return(
                                                      <tr>
                                                          <td>{file.Key.split("/")[1]}</td>
                                                          <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                          <td className="text-center">
                                                              <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                  <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                          <td className="text-center">
                                                              <a href="javascript:void(0)">
                                                                  <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                      </tr>
                                                  )
                                              })
                                          }
                                      </tbody>
                                  </table>
                              </div> :
                              <div className="col-md-12 mb-4">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <input type="file" id="capaAuxPortuguese" name="capaAuxPortuguese" required accept="image/png"
                                              onChange={this.selectFile.bind(this)} />
                                      </div>
                                      <div className="col-sm-2">
                                          <a className="button-full btn-file" onClick={() => this.upload('capaPortuguese')}>
                                              <span className="btn-label">
                                                  <i className="fa fa-upload"></i>
                                              </span> Enviar
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          }

                          <div className="col-md-12 mb-2">
                              <h5>
                                  Capa em inglês<span className="highlighter">*</span>
                              </h5>
                          </div>
                          {
                              this.state.capaEnglish.length > 0 || this.state.capaEnglish != '' ?
                              <div className="col-md-12 mb-4">
                                  <table className="table table-hover table-striped table-responsive">
                                      <thead>
                                          <tr>
                                              <th>Nome</th>
                                              <th>Data de modificação</th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody >
                                          {
                                              this.state.capaEnglish.map(file => {
                                                  return(
                                                      <tr>
                                                          <td>{file.Key.split("/")[1]}</td>
                                                          <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                          <td className="text-center">
                                                              <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                  <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                          <td className="text-center">
                                                              <a href="javascript:void(0)">
                                                                  <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                      </tr>
                                                  )
                                              })
                                          }
                                      </tbody>
                                  </table>
                              </div> :
                              <div className="col-md-12">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <input type="file" id="capaAuxEnglish" name="capaAuxEnglish" required accept="image/png"
                                              onChange={this.selectFile.bind(this)} />
                                      </div>
                                      <div className="col-sm-2">
                                          <a className="button-full btn-file" onClick={() => this.upload('capaEnglish')}>
                                              <span className="btn-label">
                                                  <i className="fa fa-upload"></i>
                                              </span> Enviar
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          }
                          <div className="col-md-12 mb-2">
                              <h5>
                                  Capa em espanhol<span className="highlighter">*</span>
                              </h5>
                          </div>
                          {
                              this.state.capaSpanish.length > 0 || this.state.capaSpanish != '' ?
                              <div className="col-md-12 mb-4">
                                  <table className="table table-hover table-striped table-responsive">
                                      <thead>
                                          <tr>
                                              <th>Nome</th>
                                              <th>Data de modificação</th>
                                              <th></th>
                                          </tr>
                                      </thead>
                                      <tbody >
                                          {
                                              this.state.capaSpanish.map(file => {
                                                  return(
                                                      <tr>
                                                          <td>{file.Key.split("/")[1]}</td>
                                                          <td>{moment(file.LastModified).format('DD/MM/YYYY')}</td>
                                                          <td className="text-center">
                                                              <a href={this.state.downloadUrl} target="_blank" rel="noreferrer">
                                                                  <i className="fa fa-download" aria-hidden="true" onClick={() => this.download(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                          <td className="text-center">
                                                              <a href="javascript:void(0)">
                                                                  <i className="fa fa-trash" aria-hidden="true" onClick={() => this.deleteFile(file.Key)}></i>
                                                              </a>
                                                          </td>
                                                      </tr>
                                                  )
                                              })
                                          }
                                      </tbody>
                                  </table>
                              </div> :
                              <div className="col-md-12">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <input type="file" id="capaAuxSpanish" name="capaAuxSpanish" required accept="image/png"
                                              onChange={this.selectFile.bind(this)} />
                                      </div>
                                      <div className="col-sm-2">
                                          <a className="button-full btn-file" onClick={() => this.upload('capaSpanish')}>
                                              <span className="btn-label">
                                                  <i className="fa fa-upload"></i>
                                              </span> Enviar
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          }
                      </div>
                      <hr />
                      <div className="row row-center">
                        <div className="col-8">
                          <small>Campos com (<span className="highlighter">*</span>) são obrigatórios</small>
                        </div>
                        <div className="col-md-4 text-right">
                          <button
                            className="button-full btn-save"
                            type="button"
                            onClick={() => this.edit()}
                          >
                            <i className="fa fa-save"></i> salvar edição
                          </button>
                        </div>
                      </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PowerEdit;