import requests from '../shared/agent';

const endpoint = '/institutions';

const InstitutionService = {
    searchInstitution: async(searchInstitution) => {
        let queryString = '?';

        if (searchInstitution.searchDateStart){
            if (queryString !== "") queryString += "&";
            queryString += `start=${searchInstitution.searchDateStart}`;
        }
        if (searchInstitution.searchDateEnd){
            if (queryString !== "") queryString += "&";
            queryString += `end=${searchInstitution.searchDateEnd}`;
        }
  
        if(queryString !== '?')
            queryString += '&';

        if(searchInstitution.limit)
            queryString += `limit=${searchInstitution.limit}`;

        if(searchInstitution.skip)
            queryString += `&skip=${searchInstitution.skip}`;

       return await requests.get(`${endpoint}/search${queryString}`)
    }

}

export default InstitutionService;