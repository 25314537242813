import requests from '../shared/agent';

const endpoint = '/powers';

const PowerService = {
    getAll: async() => {
        return await requests.get(endpoint);
    },
    getById: async(id) => {
        return await requests.get(`${endpoint}/${id}`);
    },
    create: async(power) => {
        return await requests.post(endpoint, power);
    },
    edit: async(id, power) => {
        return await requests.put(`${endpoint}/${id}`, power)
    },
    changeActive: async(id, status) => {
        return await requests.post(`${endpoint}/${id}/active`, status)
    },
    remove: async(id) => {
        return await requests.del(`${endpoint}/${id}`);
    }
};

export default PowerService;

