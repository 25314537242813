import { useState, useEffect } from "react";
import "./ParticipantInput.scss";
import DashboardService from "../../../../../services/dashboard.service";

import Swal from "sweetalert2";

export const ParticipantInput = ({ onClick, turma, participants }) => {
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [born, setBorn] = useState("");
  const [newParticipants, setNewParticipants] = useState([]);

  const changeName = (event) => setName(event.target.value.toUpperCase());
  const changeLastName = (event) =>
    setLastName(event.target.value.toUpperCase());
  const changeGender = (event) => setGender(event.target.value);
  const changeBorn = (event) => setBorn(event.target.value);

  const isValid = (value) => !!value && value.length;
  const isBornValid = (value) => {
    if (!value || !value.length) {
      return false;
    }

    const born = new Date(value + "T00:00");
    const now = new Date();
    return born <= now;
  };

  const validateBorn = async (event) => {
    if (!isBornValid(event.target.value)) {
      Swal.fire({
        title: "Ops...",
        text: "Data de nascimento fora do intervalo aceito.",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      }).then(async () => {
        onClick(turma);
      });
      return;
    }
  };

  const inputIsValid = () => {
    return (
      isValid(name) && isValid(lastName) && isValid(gender) && isBornValid(born)
    );
  };

  const participantAlreadyExist = () => {
    const fullName = name.trim() + " " + lastName.trim();
    const verifyparticipants = participants.map(
      (participant) =>        
        participant.name === fullName && participant.born === born
    );
    return verifyparticipants;
  };

  const save = async () => {
    const fullName = name.trim() + " " + lastName.trim();

    const participant = {
      class: turma.code,
      name: fullName,
      gender: gender,
      born: born,
    };

    if (!inputIsValid()) {
      Swal.fire({
        title: "Ops...",
        text: "Por favor preencha todos os campos corretamente",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      }).then(async () => {
        onClick(turma);
      });
      return;
    }

    if (participantAlreadyExist().includes(true)) {
      Swal.fire({
        title: "Ops...",
        text: "Esse participante já existe",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      }).then(async () => {
        onClick(turma);
      });
      return;
    }

    //participantAlreadyExist()

    const newParticipant = await DashboardService.saveNewParticipant(
      participant,
      turma.code
    );
    setNewParticipants([...participants, newParticipant]);

    <div id="add-participant-sucess-popup">
      {Swal.fire({
        title: "Sucesso!",
        text: "O participante foi adicionado!",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      }).then(async () => {
        window.history.pushState({}, "", `/dashboard`);
        onClick(turma);
      })}
    </div>;

    /* Swal.fire("Sucesso!", "O participante foi adicionado!", "success").then(
      async () => {
        window.history.pushState({}, "", `/dashboard`);
        onClick(turma);
       }
     ); */
  };

  return (
    <>
      <label className="add-participant-label">Nome</label>
      <input
        className="add-participant-input participant-name"
        placeholder="digite o nome..."
        type="text"
        onChange={changeName}
        value={name}
      />

      <label className="add-participant-label">Sobrenome</label>
      <input
        className="add-participant-input participant-name"
        placeholder="digite o sobrenome..."
        type="text"
        onChange={changeLastName}
        value={lastName}
      />

      <label className="add-participant-label">Gênero</label>
      <div className="add-participant-select-arrow">
        <select
          className="add-participant-select"
          // defaultValue="disabled"
          name="select"
          onChange={changeGender}
          value={gender}
        >
          <option className="choose-gender" value="">
            Escolha o gênero
          </option>
          <option value="male">Masculino</option>
          <option value="female">Feminino</option>
          <option value="other">Outros</option>
        </select>
      </div>

      <label className="add-participant-label">Data de nascimento</label>
      <input
        className="add-participant-input participant-date"
        // min={`${moment().year()-18}-01-01`}
        // max={`${moment().year()-10}-12-31`}
        onChange={changeBorn}
        onBlur={validateBorn}
        type="date"
        value={born}
      />

      <button onClick={save} className="add-participant-add-button button-full">
        {id ? "SALVAR" : "ADICIONAR"}
      </button>
    </>
  );
};
