import { add, isAfter } from "date-fns";
import LocalStorage from "./LocalStorage.service";
import showAlert from "../utils/showAlert";
import { AuthProvider } from "../context/authContext";


export class FilterService {
    #component;
    #isTheComponentAContext;

    constructor(component) {
        this.#component = component;
        this.#isTheComponentAContext = component instanceof AuthProvider;
    }

    runAllFilters() {
        this.verifyIfTokenIsExpired();
    }

    verifyIfTokenIsExpired() {
        const context = this.#isTheComponentAContext? this.#component : this.#component.context;
        const tokenExp = context?.state?.tokenExp;
        const clearContext = context?.clearContext;

        if(!tokenExp) {
            clearContext()

            showAlert({
                title: 'Sua sessão expirou, por favor faça login novamente.'
            })

            throw new Error('Data de expiração do token não existe')
        }

        const actualDate = new Date();
        const expiredDate = new Date(tokenExp);

        add(actualDate, { hours: 3 });
        const isDateAfter = isAfter(actualDate, expiredDate);

        if(isDateAfter) {
            
            LocalStorage.clear();

            showAlert({
                title: 'Sua sessão expirou, por favor faça login novamente.'
            });
        }

    }

}