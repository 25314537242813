import requests from '../shared/agent';

const endpoint = '/training'

const TrainingService = {
    getAll: async () => {
        return await requests.get(endpoint)
    },
    getById : async (id) => {
        return await requests.get(`${endpoint}/${id}`)
    },
    create: async(training) => {
        return await requests.post(endpoint, training);
    },
    edit: async(id, training) => {
        return await requests.put(`${endpoint}/${id}`, training)
    },
    changeActive: async(id, status) => {
        return await requests.post(`${endpoint}/${id}/active`, status)
    },
    remove: async(id) => {
        return await requests.del(`${endpoint}/${id}`);
    }

}

export default TrainingService