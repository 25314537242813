import moment from "moment";
import 'moment/locale/pt-br';

export default function (props) {
  return (
    <table className="table table-hover table-striped mb-5">
      <thead>
        <tr className="text-center">
          <th></th>
          <th>{props.date}</th>
          <th>Total (desde {moment().startOf('year').utc().format('DD/MM/YYYY')})</th>
        </tr>
      </thead>
      <tbody>
        {
          props.items.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item[0]}</td>
                <td className="text-center">{item[1] === undefined ? "-" : item[1]}</td>
                <td className="text-center">{item[2]}</td>
              </tr>
            )

          })
        }
      </tbody>
    </table>
  );
}

