import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InstructorService from '../../../services/instructor.service';

const MySwal = withReactContent(Swal);

const handleToggle = async (e) => {
    const id = e.target.id;
    const status = e.target.checked;

    await InstructorService.changeLicense(id, { status });

    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: "CANCELAR",
      customClass: {
        confirmButton: "confirm-button-class",
      },
      icon: "success",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: false,
      title: (
        <span style={{ color: "#FFC107" }}>
          {!status ? "Desativado" : "Ativado"}
        </span>
      ),
      html: (
        <p style={{ color: "#000" }}>
          {!status ? "A licença do instrutor foi desativada." : "A licença do instrutor foi ativada."}
        </p>
      ),
    });
  };

export default handleToggle;