import './Loading.scss';

const Loading = (props) => {

  function renderLoading() {
    return (
      <div className="row justify-content-center">
        <div className="col-md-12 loading">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <p className="text-loading">Carregando</p>
        </div>
      </div>
    )
  }
  
  return (
    props.status ? renderLoading() : props.children  
  )
}

export default Loading;