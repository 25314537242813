import { Route, Redirect } from "react-router-dom";
import Login from "../../Login/Login";

const PublicRoutes = () => {
    return (
        <>
        <Route path="/login" component={Login} />
        <Route 
            path='*'
            render={() => <Redirect to="/login" />}
        />
        </>
    )
}

export default PublicRoutes;

