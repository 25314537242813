import requests from '../shared/agent';

const endpoint = '/users'

const UserService = {
    login: async (user) => {
        return await requests.post(`/auth${endpoint}`, user)
    },
    create: async (user) => {
        return await requests.post(endpoint, user)
    }
};

export default UserService;

