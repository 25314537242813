import React, { useState } from "react";
import "./EditPresenceListStudents.scss";

export const EditPresenceListStudents = ({ workshop, onChange }) => {
  const [localWorkshop, setLocalWorkshop] = useState(workshop);
  const onChangePresence = (changedStudent) => {
    const presenceList = localWorkshop.presenceList.map((student) => {
      if (changedStudent._id === student._id) {
        return changedStudent;
      }
      return student;
    });
    setLocalWorkshop({ ...localWorkshop, presenceList });
    onChange({ ...localWorkshop, presenceList });
  };

  if (localWorkshop.presenceList.length < 1) {
    return (
      <div className="non-existent-list-notice">
        <p>
          A lista de presença desta oficina ainda não foi realizada.
        </p>
      </div>
    );
  } else {
    return (
      <ul className="edit-presence-list-content">
        <h3>{localWorkshop.pt.name}</h3>

        {localWorkshop.presenceList.map((student, index) => {
          return (
            <li key={index} className="list">
              <label className="student-name">{student.name}</label>
              <div>
                <form className="presenca-input-radio">
                  <input
                    id={`${student._id}-presenca`}
                    name="lista-presenca"
                    type="radio"
                    value="true"
                    checked={student.presence}
                    onChange={() =>
                      onChangePresence({ ...student, presence: true })
                    }
                  />
                  <label
                    className="presenca"
                    htmlFor={`${student._id}-presenca`}
                  >
                    Presença
                  </label>

                  <input
                    id={`${student._id}-falta`}
                    name="lista-presenca"
                    type="radio"
                    value="false"
                    checked={!student.presence}
                    onChange={() =>
                      onChangePresence({ ...student, presence: false })
                    }
                  />
                  <label className="falta" htmlFor={`${student._id}-falta`}>
                    Falta
                  </label>
                </form>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
};
