import React, { Component } from "react";
import moment from 'moment';

import TrashService from "../../services/trash.service";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { navigate } from "../../services/navigation.service";
import { AuthContext } from "../../context/authContext";
const MySwal = withReactContent(Swal)

class TrashCan extends Component {
  static contextType = AuthContext

  constructor() {
    super();
    this.state = {
      trash: []
    };
  }

  componentWillMount = async () => {
    let trash = await TrashService.getAll();

    trash.sort((x, y) => {
      return x.deletedAt > y.deletedAt ? -1 : 1
    })
  
    trash.forEach(item => {
      item.deletedAt = moment(item.deletedAt).format("DD/MM/YYYY HH:mm");
    })

    await this.setState({
      trash
    });
  };

  recover = (item) => {
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONCLUIR',
      customClass: {
        confirmButton: 'cancel-button-class',
        cancelButton: 'confirm-button-class'
      },
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: <span style={{ color: '#FFC107' }}>Restaurar {item.resource.name.toLowerCase()}?</span>,
      html: <p style={{ color: '#000' }}>
        Ao restaurar este recurso, ele estará novamente incluído em relatórios e apto a ser utilizado, editado ou até mesmo movido à Lixeira novamente.<br /><br />
      </p>,
    }).then(async (result) => {
      if (result.value) {
        let id = item.object._id;

        if (item.resource.address === "classes")
          id = item.object.code;

        let res = await TrashService.changeDeleted(item.resource.address, id);
        if (res._id){
          // window.location.reload();
          const navigator = navigate.bind(this);
          navigator({ reload: true });
        }
      }
    })
  }

  renderTrash() {
    let trash = [];
    console.log(trash);
    trash = this.state.trash.map((item, index) => {
      return (
        <tr key={item.object._id}>
          <td>{item.name}</td>
          <td>{item.object.class || item.object.code || ""}</td>
          <td>{item.resource.name}</td>
          <td>{item.deletedAt}</td>
          <td>
            <button
              className="ml-3 btn-edit"
              onClick={() => this.recover(item)}
            >
              restaurar
            </button>
          </td>
        </tr>
      );
    });
    return trash;
  }

  render() {
    return (
      <div className="content-card">
        <div className="row page-title">
          <h1>Lixeira</h1>
        </div>

        <div className="row content-header">
          <h3 className="list-title">Itens apagados</h3>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-responsive table-hover table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Código</th>
                  <th>Tipo</th>
                  <th>Data de exclusão</th>
                  <th className="actions">Ações</th>
                </tr>
              </thead>
              <tbody>{this.renderTrash()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TrashCan;
