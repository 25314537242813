import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SecurityService from "../services/security.service";
import SideBar from "./Side-bar/Side-bar";
import { AuthContext } from "../context/authContext";
import PrivateRoutes from "./Routes/PrivateRoutes/PrivateRoutes";
import PublicRoutes from "./Routes/PublicRoutes/PublicRoutes";


class App extends Component {
  static contextType = AuthContext

  render() {
    const isLogged = Boolean(this.context.state.user && this.context.state.token)
    // TODO: Verificar de forma mais segura se o user é o master
    const isMaster = SecurityService.isMaster()

    return (
      <BrowserRouter>
        <Route path="/" component={SideBar} />
        <Switch>
          {
            isLogged ?
            <PrivateRoutes isMaster={isMaster} /> 
            : <PublicRoutes />
          }
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
