import React from 'react';
import Item from './Item';

const List = ({ 
    data, 
    itemsPerPage, 
    startIndexToQuery, 
    endIndexToQuery, 
    currentItems 
}) => {
    const hasData = Boolean(data?.result?.length);
    const shouldPaginate = hasData && data.result.length > itemsPerPage;
    
    const conditional = shouldPaginate?
        data.result.slice(startIndexToQuery, endIndexToQuery)
        : hasData? 
        data.result
        : currentItems;    


    return (
        <>
            {conditional?.map((item, index) => 
                <Item 
                    key={index}
                    item={item} 
                    index={index} 
                />
            )}
        </>
    );
  };

  export default List;